<template>
  <v-row>
    <v-col cols="6">
      <v-row justify="space-between" align="start">
        <v-col
          id="list-crud"
          cols="12"
          sm="12"
          md="9"
          lg="9"
          xl="9"
          class="text-md-end pb-sm-0 text-sm-left py-sm-7 py-md-2"
        >
          <v-row align="center">
            <v-col cols="2" class="hidden-md-and-down">
              <span class="font-weight-bold">Filtros:</span>
            </v-col>
            <v-col cols="12" md="6" lg="3">
              <v-text-field
                v-model="refFilter"
                label="Ref contrato"
                clearable
              />
            </v-col>
            <v-col cols="12" md="5" class="hidden-md-and-down">
              <v-radio-group v-model="priceFilter" row>
                <v-radio
                  v-for="(p, i) in prices"
                  :key="i"
                  :label="p.label"
                  :value="p.value"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" lg="2">
              <v-autocomplete
                v-model="incotermFilter"
                label="Incoterm*"
                :items="incoterms"
                item-text="name"
                item-value="id"
                clearable
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="3"
          lg="3"
          xl="3"
          class="text-right mb-sm-4"
        >
          <v-btn
            class="py-sm-0 py-2 font-weight-medium"
            color="primary"
            dark
            rounded
            @click="$emit('open')"
          >
            <!-- Larges screens -->
            <span class="hidden-md-and-down">Novo contrato</span>
            <!-- Smalls screens -->
            <span class="hidden-lg-and-up">Novo</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-skeleton-loader
          v-if="load"
          v-bind="crudSkelletonAttrs"
          type="table-heading@2, list-item-two-line"
          width="100%"
        />
        <agr-data-table
          v-else
          :headers="headers"
          :items="contractsFiltered"
          :loading="load"
          :btn-actions="true"
          height="50vh"
          sort-by="id"
          @edit="edit"
          @confirmDelete="confirmDelete"
          @duplicate="duplicate"
          @click:row="detailing"
        >
          <!-- Itens slots -->
          <template v-slot:ref="{ item }">
            <span class="font-weight-medium">{{ item.ref }}</span>
          </template>
          <template v-slot:deal_date="{ item }">
            <span>{{ item.deal_date | formatterBRZDate }}</span>
          </template>
        </agr-data-table>
      </v-row>
    </v-col>
    <v-col cols="6">
      <v-row>
        <v-col class="text-center text-h3 font-weight-bold">
          <span>Detalhamento do contrato</span>
        </v-col>
      </v-row>
      <v-row class="px-6 fill-height pb-8">
        <v-col style="border: 1px solid black" class="rounded-lg">
          <v-row v-if="!contractSelected">
            <v-col class="text-center">
              <span>Nenhum contrato selecionado</span>
            </v-col>
          </v-row>

          <v-row v-else class="text-h3 pa-2">
            <v-col>
              <v-row>
                <v-col cols="auto">
                  <span class="mr-2 font-weight-bold">Contrato:</span
                  ><span class="ml-2">{{ contractSelected.ref }}</span>
                </v-col>
                <v-col cols="auto">
                  <span class="mr-2 font-weight-bold">Ref. Corretora:</span
                  ><span class="ml-2">{{
                    contractSelected.brokerage_ref
                  }}</span>
                </v-col>
                <v-col cols="auto">
                  <span class="mr-2 font-weight-bold">Ref. Comprador:</span
                  ><span class="ml-2">{{ contractSelected.buyer_ref }}</span>
                </v-col>
              </v-row>
              <v-divider />
              <v-row>
                <v-col class="text-center"
                  ><span class="font-weight-bold">Produto</span></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="auto">
                  <span class="mr-2 font-weight-bold">Cultura:</span
                  ><span class="ml-2">{{ contractSelected.growing.name }}</span>
                </v-col>
                <v-col cols="auto">
                  <span class="mr-2 font-weight-bold">Safra:</span
                  ><span class="ml-2">{{ contractSelected.harvest.name }}</span>
                </v-col>
                <v-col cols="auto">
                  <span class="mr-2 font-weight-bold">Preço fixo:</span
                  ><span class="ml-2">{{
                    contractSelected.fixed_price ? "Sim" : "Não"
                  }}</span>
                </v-col>
                <v-col cols="auto">
                  <span class="mr-2 font-weight-bold">Data:</span
                  ><span class="ml-2">{{
                    contractSelected.deal_date | formatterBRZDate
                  }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="auto">
                  <span class="mr-2 font-weight-bold">Operação:</span
                  ><span class="ml-2">{{
                    enumOperation(contractSelected.operation)
                  }}</span>
                </v-col>
                <v-col cols="auto">
                  <span class="mr-2 font-weight-bold">Vendedor:</span
                  ><span class="ml-2">{{ contractSelected.seller.name }}</span>
                </v-col>
                <v-col cols="auto">
                  <span class="mr-2 font-weight-bold">Comprador:</span
                  ><span class="ml-2">{{ contractSelected.buyer.name }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="auto">
                  <span class="mr-2 font-weight-bold">Quantidade:</span
                  ><span class="ml-2">{{
                    contractSelected.amount | formatterUnit(3)
                  }}</span>
                </v-col>
                <v-col cols="auto">
                  <span class="mr-2 font-weight-bold">Unidade:</span
                  ><span class="ml-2">{{ contractSelected.unit.symbol }}</span>
                </v-col>
                <v-col cols="auto">
                  <span class="mr-2 font-weight-bold">Moeda:</span
                  ><span class="ml-2">{{
                    contractSelected.currency.name
                  }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-center"
                  ><span class="font-weight-bold">Pagamento</span></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="auto">
                  <span class="mr-2 font-weight-bold">Forma:</span
                  ><span class="ml-2">{{
                    enumPayment(contractSelected.payment)
                  }}</span>
                </v-col>
                <v-col cols="auto">
                  <span class="mr-2 font-weight-bold">Dias:</span
                  ><span class="ml-2">{{
                    contractSelected.payment_days_after
                  }}</span>
                </v-col>
                <v-col cols="auto">
                  <span class="mr-2 font-weight-bold">Incoterm:</span
                  ><span class="ml-2">{{
                    contractSelected.incoterm.name
                  }}</span>
                </v-col>
                <v-col cols="auto">
                  <span class="mr-2 font-weight-bold">Exportação:</span
                  ><span class="ml-2">{{
                    contractSelected.is_export ? "Sim" : "Não"
                  }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-center"
                  ><span class="font-weight-bold">Corretagem</span></v-col
                >
              </v-row>
              <v-row v-if="contractSelected.brokerage">
                <v-col cols="auto">
                  <span class="mr-2 font-weight-bold">Corretora:</span
                  ><span class="ml-2">{{
                    contractSelected.brokerage.name
                  }}</span>
                </v-col>
                <v-col v-if="contractSelected.brokerage_percent" cols="auto">
                  <span class="mr-2 font-weight-bold">Percentual:</span
                  ><span class="ml-2">{{
                    contractSelected.brokerage_percent
                  }}</span>
                </v-col>
                <v-col v-if="contractSelected.brokerage_value" cols="auto">
                  <span class="mr-2 font-weight-bold">Valor:</span
                  ><span class="ml-2">{{
                    contractSelected.brokerage_value
                  }}</span>
                </v-col>
                <v-col v-if="contractSelected.brokerage_value" cols="auto">
                  <span class="mr-2 font-weight-bold">Unidade:</span
                  ><span class="ml-2">{{
                    contractSelected.brokerage_unit.symbol
                  }}</span>
                </v-col>
                <v-col v-if="contractSelected.brokerage_value" cols="auto">
                  <span class="mr-2 font-weight-bold">Moeda:</span
                  ><span class="ml-2">{{
                    contractSelected.brokerage_currency.name
                  }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="auto">
                  <span class="mr-2 font-weight-bold">Obs:</span
                  ><span class="ml-2">{{ contractSelected.note }}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import CrudMixins from "@/mixins/crud";
import { tradeslip } from "@/mixins/tradeslip";
import { mapGetters } from "vuex";

export default {
  name: "TradeslipContract",
  mixins: [tradeslip, CrudMixins],
  props: {
    gColor: String,
  },
  data() {
    return {
      // Filters
      refFilter: undefined,
      priceFilter: undefined,
      incotermFilter: undefined,
      prices: [
        { label: "Preço fixo", value: true },
        { label: "Preço a fixar", value: false },
      ],
      contractSelected: undefined,
      headers: [
        {
          text: "Referência do contrato",
          tooltip: "Referência gereda pelo sistema",
          align: "left",
          value: "ref",
        },
        {
          text: "Data",
          tooltip: "Data do acordo",
          align: "center",
          value: "deal_date",
        },
        {
          text: "Ações",
          align: "center",
          tooltip: "Modificar ou excluir o contrato",
          value: "actions",
          sortable: false,
        },
      ],
    };
  },

  methods: {
    searchContracts() {
      let payload = {
        resource: "tradeslip",
        params: {
          my: true,
          checkDelete: true,
        },
      };
      this.load = true;
      this.search(payload).then(() => {
        this.load = false;
      });
    },
    detailing(item) {
      this.contractSelected = Object.assign({}, item);
    },
    enumStatus(value) {
      if (value === "NEW") return "Novo";
      if (value === "REGISTERED") return "Registrado";
      return "N/A";
    },
    enumOperation(value) {
      if (value === "SALE") return "Venda";
      return "N/A";
    },
    enumPayment(value) {
      if (value === "AFTER_DELIVERY") return "Após a Entrega";
      if (value === "AFTER_INVOICE") return "Após a emissão da nota";
      if (value === "LOAD_VOLUME") return "Volume Carregado";
      if (value === "INSTALLMENT_DATE") return "Fracionado (data)";
      if (value === "INSTALLMENT_AMOUNT") return "Fracionado (quantidade)";
      return "N/A";
    },
    confirmDelete(dcontract) {
      this.setResource("tradeslip");
      dcontract.is_deleted = true;
      dcontract.deleted_date = new Date().toISOString();
      this.saveOrUpdate(dcontract)
        .then(() => {
          this.$alertSuccess("Contrato deletedo com sucesso!");
          this.contractSelected = undefined;
          this.searchContracts();
        })
        .catch(() => {
          this.$alertError("Houve um problema ao deletar o contrato!");
        });
    },
    edit(item) {
      this.$emit("edit", item);
    },
  },
  computed: {
    ...mapGetters({
      incoterms: "getIncoterms",
    }),
    contractsFiltered() {
      // criteria definida no crud mixin
      if (!this.criteria.length) return this.results.objects;
      // propotype definido no crud mixin
      return this.results.objects.dynamicFilter(this.criteria);
    },
  },
  created() {
    this.searchContracts();
  },
  watch: {
    refFilter(val) {
      // função do crud mixin
      this.createUpdateCriteria(val, "ref");
    },
    priceFilter(val) {
      // função do crud mixin
      this.createUpdateCriteria(val, "fixed_price");
    },
    incotermFilter(val) {
      // função do crud mixin
      this.createUpdateCriteria(val, "incoterm_id");
    },
  },
};
</script>
