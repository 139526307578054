<template>
  <div>
    <v-row v-if="notApplicable">
      <v-col cols="12" class="pb-0 pt-4" align="center">
        <v-row justify=center>
          <v-col cols="auto">
            <v-alert
              border="left"
              colored-border
              type="info"
              elevation="1"
            >
              <span class="text-h4 font-weight-light">
                O Incoterm deste contrato não se aplica a retirada por parte do comprador
              </span>
            </v-alert>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-form ref="mainForm">
          <v-row>
            <v-col cols="auto">
              <v-autocomplete
                v-model="contractSilo.silo_id"
                label="Retirada"
                :items="silos"
                item-text="name"
                item-value="id"
                :color="gColor"
                :disabled="disableSilos || notApplicable"
                :rules="rulesSilos"
                outlined
                dense
                clearable
                @change="disableLocalAddress"/>
            </v-col>
            <v-col cols="auto">
              <v-text-field
                v-model="contractSilo.address"
                label="Logradouro"
                :color="gColor"
                outlined
                dense
                :disabled="disableAddress || notApplicable"
                @input="disableLocalSilos"/>
            </v-col>
            <v-col cols="auto">
              <v-text-field
                v-model="contractSilo.address_complement"
                label="Complemento"
                :color="gColor"
                outlined
                dense
                :disabled="disableAddress || notApplicable"
                disableLocalSilos
                @input="disableLocalSilos"/>
            </v-col>
            <v-col cols="auto">
              <agr-city-selector
                v-model="contractSilo.city_id"
                label="Cidade"
                :color="gColor"
                outlined
                dense
                :disabled="disableAddress || notApplicable"/>
            </v-col>
            <v-col cols="auto">
              <v-currency-field
                v-model="contractSilo.amount"
                label="Quantidade*"
                :suffix="contract.unit.symbol"
                :color="gColor"
                :rules="[rules.required, rules.nozero, rules.maxAmount, rules.sumResourceMaxAmount]"
                :disabled="notApplicable"
                validate-on-blur
                outlined
                dense/>
            </v-col>
            <v-col cols="auto">
              <v-btn
                color="primary"
                :disabled="notApplicable"
                @click="save">
                <v-icon left>
                  {{ contractSilo.id ? 'mdi-autorenew' : 'mdi-plus' }}
                </v-icon>
                {{ contractSilo.id ? 'Atualizar' : 'Adicionar' }}
              </v-btn>
            </v-col>
            <v-col cols="auto" v-if="contractSilo.id">
              <v-btn
                color="red"
                @click="clear">
                <v-icon left>
                  mdi-close
                </v-icon>
                Cancelar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-skeleton-loader
        v-if="quering"
        v-bind="crudSkelletonAttrs"
        type="table-heading@2, list-item-two-line"
        width="100%"/>

      <v-data-table
        v-else
        :headers="headers"
        :items="contractSilos"
        :items-per-page="10"
        :loading="quering"
        sort-by="id"
        class="elevation-0 table-full-width"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-arrow-left',
          nextIcon: 'mdi-arrow-right'
        }">
        <template v-slot:[`header.silo.name`]="{ header }">
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
            </template>
            <span>{{ header.tooltip }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`header.address`]="{ header }">
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
            </template>
            <span>{{ header.tooltip }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`header.address_complement`]="{ header }">
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
            </template>
            <span>{{ header.tooltip }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`header.city`]="{ header }">
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
            </template>
            <span>{{ header.tooltip }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`header.amount`]="{ header }">
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
            </template>
            <span>{{ header.tooltip }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`header.actions`]="{ header }">
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
            </template>
            <span>{{ header.tooltip }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.silo.name`]="{ item }">
          <span v-if="item.silo" class="font-weight-medium">{{ item.silo.name }}</span>
          <span v-else class="font-weight-medium">Sem cadastro</span>
        </template>
        <template v-slot:[`item.address`]="{ item }">
          <span v-if="item.silo">{{ item.silo.address }}</span>
          <span v-else>{{ item.address }}</span>
        </template>
        <template v-slot:[`item.address_complement`]="{ item }">
          <span v-if="item.silo">{{ item.silo.address_complement }}</span>
          <span v-else>{{ item.address_complement }}</span>
        </template>
        <template v-slot:[`item.city`]="{ item }">
          <span v-if="item.silo">{{ item.silo.city.name }}</span>
          <span v-else>{{ item.city.name }}</span>
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          <span>{{ item.amount | formatterUnit(3) }} {{ contract.unit.symbol }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <agr-actions-buttons-table
            :item="item"
            @edit="edit"
            @confirmDelete="confirmDelete"/>
        </template>
        <template v-slot:no-data>
          <v-container
            fluid
            fill-height
            style="background-color: rgba(255, 255, 255, 0.5);"
          >
            <v-layout justify-center align-center>
              <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
              <strong
                v-else
                class="py-2"
                style="font-color:red;font-size:14px"
              >Nenhum silo encontrado para este contrato</strong>
            </v-layout>
          </v-container>
        </template>
      </v-data-table>
    </v-row>
  </div>
</template>

<script>
import { tradeslip } from "@/mixins/tradeslip";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ContractSilo",
  mixins: [tradeslip],
  props: {
    contract: Object,
    gColor: String,
  },

  data() {
    return {
      contractSilo: undefined,
      disableAddress: false,
      disableSilos: false,
      localRules: undefined,
      isUpdating: false,

      headers: [
        {
          text: "Silo",
          tooltip: "Silo marcado para retirada",
          align: "left",
          value: "silo.name",
        },
        {
          text: "Logradouro",
          tooltip: "Endereço do silo",
          align: "center",
          value: "address",
        },
        {
          text: "Complemento",
          tooltip: "Complemento do endereço",
          align: "center",
          value: "address_complement",
        },
        {
          text: "Cidade",
          tooltip: "Cidade do endereço",
          align: "center",
          value: "city",
        },
        {
          text: "Quantidade",
          tooltip: "Quantidade armazenada no silo",
          align: "center",
          value: "amount",
        },
        {
          text: "Ações",
          align: "center",
          tooltip: "Modificar ou excluir o silo do contrato",
          value: "actions",
          sortable: false,
        },
      ],
      defaultContractSilo: {
        amount: null,
        address: undefined,
        address_complement: undefined,
        tradeslip_id: undefined,
        city_id: undefined,
        silo_id: undefined,
      },
    };
  },

  computed: {
    ...mapGetters({
      contractSilos: "getContractSilos",
      silos: "getSilos",
      quering: "getQuering",
    }),
    rulesSilos() {
      if (
        this.contractSilo.address ||
        this.contractSilo.address_complement ||
        this.contractSilo.city_id
      ) {
        return [this.rules.norequired];
      }
      return [this.rules.required];
    },
    notApplicable() {
      if (this.contract.incoterm.name !== "EXW") {
        return true;
      }
      return false;
    },
  },

  methods: {
    ...mapActions({
      searchTradeslipDeliveries: "SEARCH_TRADESLIP_DELIVERY_PERIOD",
    }),
    save() {
      if (this.validate()) {
        this.contractSilo.tradeslip_id = this.contract.id;

        this.setResource("tradeslip_silo");
        this.saveOrUpdate(this.contractSilo)
          .then((response) => {
            this.$alertSuccess("Silo adicionado com sucesso no contrato");
            this.updateResourceList("contractSilos", response);
            this.calcSumAmounts();
            this.clear();
          })
          .catch(() => {
            this.$alertError(
              "Houve um problema para adicionar o silo no contrato"
            );
          });
      }
    },
    initialize() {
      this.contractSilo = Object.assign({}, this.defaultContractSilo);
      this.disableAddress = false;
      this.disableSilos = false;
    },
    edit(item) {
      this.contractSilo = Object.assign({}, item);
      this.calcSumAmounts();
    },
    confirmDelete(dcontractsilo) {
      this.setResource("tradeslip_silo");
      this.delete(dcontractsilo.id)
        .then(() => {
          this.$alertSuccess("Silo removido com sucesso!");
          this.removeFromResourceList("contractSilos", dcontractsilo);
          this.calcSumAmounts();
        })
        .catch(() => {
          this.$alertError("Houve um problema ao remover o silo");
        });
    },
    disableLocalAddress() {
      if (this.contractSilo.silo_id) {
        this.disableAddress = true;
      } else {
        this.disableAddress = false;
      }
    },
    disableLocalSilos() {
      if (
        this.contractSilo.address ||
        this.contractSilo.address_complement ||
        this.contractSilo.city_id
      ) {
        this.disableSilos = true;
      } else {
        this.disableSilos = false;
      }
    },
    calcSumAmounts() {
      // Soma os valores dos silos já cadastrados
      // para validação pela rules
      // sumResourceAmount FROM MIXIN
      if (this.contractSilo.id) {
        // Remove o item em atualização
        // para não contar na soma
        let arr = this.contractSilos.filter(
          (cd) => cd.id !== this.contractSilo.id
        );
        if (arr && arr.length > 0)
          this.sumResourceAmount = arr.reduce(function (
            accumulator,
            currentValue
          ) {
            return accumulator + parseFloat(currentValue.amount);
          },
          0);
        else this.sumResourceAmount = 0;
      } else {
        this.sumResourceAmount = this.contractSilos.reduce(function (
          accumulator,
          currentValue
        ) {
          return accumulator + parseFloat(currentValue.amount);
        },
        0);
      }
    },
  },

  created() {
    this.initialize();
  },
  watch: {
    // Soma as qtdes dos silos salvos
    quering(val) {
      if (!val) {
        this.calcSumAmounts();
      }
    },
  },
};
</script>

<style>
</style>