
import { mapGetters, mapMutations, mapActions } from "vuex"; // mapState
import { decimalBRZToNumber } from '@/utilsNumber';
import { dateISONotNull } from '@/utilsDate';

export const tradeslip = {
  data() {
    return {
      load: false,
      criteria: [],
      classesTableHeader: "font-weight-bold text-h4 text-md-h5 text-sm-subtitle-1",
      sumResourceAmount: 0,
      // operação no hedge cambial
      trade_operation: [
        {
          name: "Venda",
          value: 'SALE'
        },
        {
          name: "Compra",
          value: 'PURCHASE'
        }
      ],
      rules: {
        required: value => !!value || 'Campo obrigatório.',
        norequired: () => true,
        requiredwithzero: value => {
          return (typeof value !== 'undefined' && value !== null) || 'Campo obrigatório.'
        },
        nozero: value => {
          if(!value) return true;
          return !!decimalBRZToNumber(value) || 'Campo obrigatório.'
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'E-mail inválido.'
        },
        // Verifica se o valor digitado não é maior que a quantidade do contrato
        maxAmount: value => {
          if(!this.contract || !value) return true;
          return decimalBRZToNumber(value) <= parseFloat(this.contract.amount) || 'Valor superior ao contrato'
        },
        // Verifica se o valor digitado mais a soma dos silos não é maior que a quantidade do contrato
        sumResourceMaxAmount: value => {
          if(!this.contract || !value) return true;
          let sumAmount = decimalBRZToNumber(value) + this.sumResourceAmount;
          return sumAmount <= parseFloat(this.contract.amount) || 'Soma das quantidades é superior ao contrato'
        }
      },
      // from skeleton
      crudSkelletonAttrs: {
        class: 'mb-6',
        elevation: 0,
      },
      amountOptions: {
        locale: "pt-BR",
        prefix: "",
        suffix: "",
        length: 15,
        precision: 2
      },
      currencyOptions: {
        locale: "pt-BR",
        prefix: "",
        suffix: "",
        length: 15,
        precision: 2
      },
      brokerageOptions: {
        locale: "pt-BR",
        prefix: "",
        suffix: "%",
        length: 5,
        precision: 2
      },
    }
  },

  methods: {
    ...mapMutations(["setResource", "updateList", "removeList"]),
    ...mapActions({
      myOwnerships: "MY_OWNERSHIPS",
      search: "SEARCH_CRUD",
      getResource: "SIMPLE_SEARCH",
      getResourceById: "SEARCH_BY_ID",
      resetSearch: "RESET_SEARCH",
      delete: "DELETE",
      saveOrUpdate: "SAVE_UPDATE"
    }),
    convertDollar(item) {
      if(item.currency){
        if(item.currency.identifier === 'D') {
          return item.value * item.reference_value
        }
        return item.value
      }
    },
    createUpdateCriteria(value, field) {
      // CRIA OU ATUALIZA o filtro nos criterios
      // busca se já existe o filtro
      let index = this.criteria.findIndex(c => c.field === field);
      if(typeof value !== 'undefined' && value !== null) {
        if(index > -1){
          // se true atualiza
          this.criteria[index].value = value
        }else {
          // se false cria o novo filtro
          this.criteria.push({
            field: field,
            value: value
          });
        }
      }else {
        // remove o item quando o filtro limpa
        if(index > -1) this.criteria.splice(index, 1);
      }
    },
    reset() {
      this.$refs.mainForm.resetValidation();
    },
    validate() {
      return this.$refs.mainForm.validate();
    },
    // Faz voltar os campos para o estado inicial
    clear() {
      this.initialize();
      this.reset();
    },
    dateISONotNull(date, time=true) {
      return dateISONotNull(date, time);
    },
    updateResourceList(array, item) {
      this.updateList({
        array: array,
        item: item
      });
    },
    removeFromResourceList(array, item) {
      this.removeList({
        array: array,
        item: item
      });
    },
    nameEnumTradeOperation(value) {
      switch (value) {
        case 'SALE':
          return 'Venda';
        case 'PURCHASE':
          return 'Compra';

        default:
          return 'error';
      }
    }
  },
  computed: {
    ...mapGetters({
      harvests: "getHarvests",
      growings: "getGrowings",
      allCurrencies: "getCurrencies",
      AllUnits: "getUnits",
      page: "getPagination",
      user: "getLoggedUser",
      results: "getResults",
      loading: "getLoading",
    }),
    resourceFiltered() {
      if((!this.harvestSelected || typeof this.harvestSelected === "undefined") && (!this.growingSelected || typeof this.growingSelected === "undefined")) {
        return this.results.objects
      }
      if(!this.harvestSelected || typeof this.harvestSelected === "undefined") {
        return this.results.objects.filter(f => f.growing_id === this.growingSelected);
      }
      if(!this.growingSelected || typeof this.growingSelected === "undefined") {
        return this.results.objects.filter(f => f.harvest_id === this.harvestSelected);
      }
      return this.results.objects.filter(f => f.harvest_id === this.harvestSelected && f.growing_id === this.growingSelected);
    },
    currencies() {
      return this.allCurrencies.filter(curr => curr.identifier === 'D' || curr.identifier === 'R');
    },
    units() {
      return this.AllUnits.filter(unit => !unit.symbol.includes('km') && !unit.symbol.includes('ha'));
    }
  },

  beforeCreate() {
    Array.prototype.dynamicFilter = function(criterias) {
      // Cria uma nova protoipação para os arrays

      // Set our variables
      let matchesFilter, matches = [], count = 0;

      matchesFilter = function(item) {
        // Recebe o item o array que esta sendo filtrado
        // e compara com os criterios (criterias)
        criterias.forEach(c => {
          // Check pra ver se o item exite para comparar
          if(item[c.field] !== 'undefined' && item[c.field] !== null){
            // checagem diferente para o tipo do item
            if(typeof c.value === 'string'){
              if(item[c.field].toLowerCase().includes(c.value.toLowerCase())) {
                count++;
              }
            }else {
              if(c.value === item[c.field]) count++;
            }
          }
        });
        // If TRUE, then the current item in the array meets
        // all the filter criteria
        let result = count == criterias.length
        count = 0;
        return result;
      };

      this.forEach(element => {
        // Percorre os item do array filtrado
        // se o element passou por todos os filtros add ele no return
        if(matchesFilter(element)) {
          matches.push(element);
        }
      });

      return matches;
    };
  },

  beforeDestroy() {
    this.resetSearch();
  },
}