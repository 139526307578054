<template>
  <div>
    <v-row>
      <v-col class="text-center">
        <span class="text-h3 primary--text font-weight-medium">Adiantamento de Contrato de Câmbio</span>
      </v-col>
    </v-row>
    <v-scroll-y-transition group  tag="div">
      <v-row v-if="quering" :key="1">
        <v-skeleton-loader
          v-bind="crudSkelletonAttrs"
          type="table-heading@2, list-item-two-line"
          width="100%"
        />
      </v-row>
      <div v-else :key="2">
        <v-row>
          <v-col>
            <v-form ref="mainForm">
              <v-row>
                <v-col cols="auto">
                  <v-autocomplete
                    v-model="acc.bank_id"
                    label="Banco*"
                    :items="banks"
                    item-text="name"
                    item-value="id"
                    :color="gColor"
                    :rules="[rules.required]"
                    validate-on-blur
                    outlined
                    dense
                    required/>
                </v-col>
                <v-col cols="auto">
                  <v-text-field
                    v-model="acc.contract"
                    :color="gColor"
                    :rules="[rules.required]"
                    label="Contrato*"
                    outlined
                    dense
                    required/>
                </v-col>
                <v-col cols="auto">
                  <agr-date-picker
                    v-model="acc.contract_date"
                    label="Contratação*"
                    :color="gColor"
                    outlined
                    dense
                    v-on:change="manualInputDate($event, 'contract_date')"/>
                </v-col>
                <v-col cols="auto">
                  <agr-date-picker
                    v-model="acc.due_date"
                    label="Vencimento*"
                    :color="gColor"
                    outlined
                    dense
                    v-on:change="manualInputDate($event, 'due_date')"/>
                </v-col>
                <v-col cols="1">
                  <v-text-field
                    :value="contractPeriod"
                    :color="gColor"
                    label="Prazo*"
                    outlined
                    dense
                    readonly/>
                </v-col>
                <v-col cols="auto">
                  <v-currency-field
                    v-model="acc.notional"
                    label="Montante*"
                    :color="gColor"
                    :rules="[rules.required]"
                    :prefix="suffixSelectedCurrency.prefix"
                    :locale="suffixSelectedCurrency.locale"
                    outlined
                    dense/>
                </v-col>
                <v-col cols="1">
                  <v-autocomplete
                    v-model="acc.currency_id"
                    label="Moeda*"
                    :items="currencies"
                    item-text="name"
                    item-value="id"
                    :color="gColor"
                    :rules="[rules.required]"
                    validate-on-blur
                    outlined
                    dense
                    required/>
                </v-col>
                <v-col cols="1">
                  <v-currency-field
                    v-model="acc.fee"
                    label="Taxa*"
                    :color="gColor"
                    :rules="[rules.required]"
                    :prefix="suffixOpositeCurrency.prefix"
                    :locale="suffixOpositeCurrency.locale"
                    :decimalLength="4"
                    outlined
                    dense/>
                </v-col>
                <v-col cols="auto">
                  <v-currency-field
                    :value="notionalConverted"
                    label="Montante*"
                    :color="gColor"
                    :prefix="suffixOpositeCurrency.prefix"
                    :locale="suffixOpositeCurrency.locale"
                    outlined
                    dense
                    readonly/>
                </v-col>
                <v-col cols="1">
                  <v-currency-field
                    v-model="acc.interest"
                    label="Juros (%)*"
                    :color="gColor"
                    :rules="[rules.required]"
                    :prefix="suffixOpositeCurrency.prefix"
                    :locale="suffixOpositeCurrency.locale"
                    outlined
                    dense/>
                </v-col>
                <v-col cols="1">
                  <v-autocomplete
                    v-model="acc.interest_frequency"
                    :items="interest_frequencies"
                    item-text="name"
                    item-value="value"
                    :color="gColor"
                    outlined
                    dense
                    required
                    readonly/>
                </v-col>
                <v-col cols="auto">
                  <v-currency-field
                    :value="nominalInterest"
                    label="Juros"
                    :color="gColor"
                    :prefix="dollarConfig.prefix"
                    :locale="dollarConfig.locale"
                    outlined
                    dense
                    readonly/>
                </v-col>
                <v-col cols="auto">
                  <v-currency-field
                    :value="nominalInterestConverted"
                    label="Juros"
                    :color="gColor"
                    :prefix="realConfig.prefix"
                    :locale="realConfig.locale"
                    outlined
                    dense
                    readonly/>
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    color="primary"
                    @click="save">
                    <v-icon left>
                      {{ acc.id ? 'mdi-autorenew' : 'mdi-plus' }}
                    </v-icon>
                    {{ acc.id ? 'Atualizar' : 'Adicionar' }}
                  </v-btn>
                </v-col>
                <v-col cols="auto" v-if="acc.id">
                  <v-btn
                    color="red"
                    @click="clear">
                    <v-icon left>
                      mdi-close
                    </v-icon>
                    Cancelar
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-row>
          <v-data-table
            :headers="headers"
            :items="accs"
            :items-per-page="10"
            :loading="quering"
            sort-by="id"
            class="elevation-0 table-full-width"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-arrow-left',
              nextIcon: 'mdi-arrow-right'
            }">
            <template v-slot:[`header.contract`]="{ header }">
              <v-tooltip top dark>
                <template v-slot:activator="{ on }">
                  <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
                </template>
                <span>{{ header.tooltip }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`header.contract_date`]="{ header }">
              <v-tooltip top dark>
                <template v-slot:activator="{ on }">
                  <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
                </template>
                <span>{{ header.tooltip }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`header.due_date`]="{ header }">
              <v-tooltip top dark>
                <template v-slot:activator="{ on }">
                  <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
                </template>
                <span>{{ header.tooltip }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`header.notional`]="{ header }">
              <v-tooltip top dark>
                <template v-slot:activator="{ on }">
                  <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
                </template>
                <span>{{ header.tooltip }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`header.fee`]="{ header }">
              <v-tooltip top dark>
                <template v-slot:activator="{ on }">
                  <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
                </template>
                <span>{{ header.tooltip }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`header.notionalConverted`]="{ header }">
              <v-tooltip top dark>
                <template v-slot:activator="{ on }">
                  <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
                </template>
                <span>{{ header.tooltip }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`header.actions`]="{ header }">
              <v-tooltip top dark>
                <template v-slot:activator="{ on }">
                  <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
                </template>
                <span>{{ header.tooltip }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.contract`]="{ item }">
              <span class="font-weight-medium">{{ item.contract }}</span>
            </template>
            <template v-slot:[`item.contract_date`]="{ item }">
              <span>{{ item.contract_date | formatterBRZDate }}</span>
            </template>
            <template v-slot:[`item.due_date`]="{ item }">
              <span>{{ item.due_date | formatterBRZDate }}</span>
            </template>
            <template v-slot:[`item.notional`]="{ item }">
              <span v-if="isDollarContract(item.currency_id)">{{ item.notional | formatterDollar }}</span>
              <span v-else>{{ item.notional | formatterReal }}</span>
            </template>
            <template v-slot:[`item.fee`]="{ item }">
              <span v-if="isDollarContract(item.currency_id)">R$ {{ item.fee | formatterUnit(4) }}</span>
              <span v-else>{{ item.fee | formatterDollar }}</span>
            </template>
            <template v-slot:[`item.notionalConverted`]="{ item }">
              <span v-if="isDollarContract(item.currency_id)">{{ (item.notional * item.fee) | formatterReal }}</span>
              <span v-else>{{ (item.notional/item.fee) | formatterDollar }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <agr-actions-buttons-table
                :item="item"
                @edit="edit"
                @confirmDelete="confirmDelete"/>
            </template>
            <template v-slot:no-data>
              <v-container
                fluid
                fill-height
                style="background-color: rgba(255, 255, 255, 0.5);"
              >
                <v-layout justify-center align-center>
                  <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
                  <strong
                    v-else
                    class="py-2"
                    style="font-color:red;font-size:14px"
                  >Nenhum ACC encontrada para este contrato</strong>
                </v-layout>
              </v-container>
            </template>
          </v-data-table>
        </v-row>
      </div>
    </v-scroll-y-transition>
  </div>
</template>

<script>
import { tradeslip } from "@/mixins/tradeslip";
import { mapGetters } from "vuex";
import { decimalBRZToNumber } from '@/utilsNumber';

export default {
  name: "ContractSilo",
  mixins: [tradeslip],
  props: {
    contract: Object,
    gColor: String,
  },
  data() {
    return {
      acc: undefined,
      interest_frequencies: [
        {
          name: "a.a.",
          value: "PER_YEAR",
        },
      ],
      rulesDelivery: {
        maxAmountDelivery: (value) => {
          if (!this.contractDelivery || !value) return true;
          return (
            decimalBRZToNumber(value) <=
              parseFloat(this.contractDelivery.amount) ||
            "Valor superior a quantidade"
          );
        },
      },
      headers: [
        {
          text: "Contrato",
          tooltip: "Referência contrato ACC",
          align: "left",
          value: "contract",
        },
        {
          text: "Contratação",
          tooltip: "Data da contratação",
          align: "left",
          value: "contract_date",
        },
        {
          text: "Vencimento",
          tooltip: "Data da contratação",
          align: "left",
          value: "due_date",
        },
        {
          text: "Montante",
          tooltip: "Valor contratado",
          align: "center",
          value: "notional",
        },
        {
          text: "Taxa",
          tooltip: "Taxa acordada",
          align: "center",
          value: "fee",
        },
        {
          text: "Montante",
          tooltip: "Valor contratado",
          align: "center",
          value: "notionalConverted",
        },
        {
          text: "Ações",
          align: "center",
          tooltip: "Modificar ou excluir o contrato ACC",
          value: "actions",
          sortable: false,
        },
      ],
      defaultAcc: {
        contract: undefined,
        contract_date: undefined,
        due_date: undefined,
        notional: undefined,
        fee: undefined,
        interest: undefined,
        interest_frequency: "PER_YEAR",
        currency_id: undefined,
        bank_id: undefined,
        tradeslip_id: undefined,
      },
      dollarConfig: {
        prefix: "$",
        locale: "en-US",
      },
      realConfig: {
        prefix: "R$",
        locale: "pt-BR",
      },
    };
  },
  computed: {
    ...mapGetters({
      accs: "getAccs",
      banks: "getBanks",
      quering: "getQuering",
    }),
    contractPeriod() {
      if (this.acc.contract_date && this.acc.due_date) {
        const date = this.$luxon(this.acc.contract_date);
        const due = this.$luxon(this.acc.due_date);
        let diff = due.diff(date, "days").toObject();
        return diff.days;
      }
      return 0;
    },
    selectedOpositeContractCurrency() {
      let currency = this.currencies.filter(
        (c) => c.id !== this.contract.currency_id
      );
      return currency.length > 0 ? currency[0].id : undefined;
    },
    currencySelected() {
      if (this.acc.currency_id) {
        return this.currencies.find((c) => c.id === this.acc.currency_id);
      }
      return undefined;
    },
    notionalConverted() {
      if (this.acc.fee && this.acc.notional && this.currencySelected) {
        let notional = parseFloat(this.acc.notional);
        let fee = parseFloat(this.acc.fee);
        if (this.currencySelected.identifier === "D") {
          return notional * fee;
        } else {
          return notional / fee;
        }
      }
      return 0;
    },
    suffixSelectedCurrency() {
      if (this.currencySelected.identifier === "D") {
        return this.dollarConfig;
      }
      return this.realConfig;
    },
    suffixOpositeCurrency() {
      if (this.currencySelected.identifier === "D") {
        return this.realConfig;
      }
      return this.dollarConfig;
    },
    nominalInterest() {
      if (this.acc.notional && this.acc.interest && this.contractPeriod) {
        return (
          this.acc.notional *
          (this.acc.interest / 100) *
          (this.contractPeriod / 360)
        );
      }
      return 0;
    },
    nominalInterestConverted() {
      if (this.notionalConverted && this.acc.interest && this.contractPeriod) {
        return (
          this.notionalConverted *
          (this.acc.interest / 100) *
          (this.contractPeriod / 360)
        );
      }
      return 0;
    },
  },
  methods: {
    save() {
      if (this.validate()) {
        this.acc.tradeslip_id = this.contract.id;
        // Verifica se uma data foi selecionada
        // Senão utiliza a padrao
        this.acc.contract_date = this.dateISONotNull(this.acc.contract_date);
        this.acc.due_date = this.dateISONotNull(this.acc.due_date);

        this.setResource("tradeslip_exchange_acc");
        this.saveOrUpdate(this.acc)
          .then((response) => {
            this.$alertSuccess("ACC adicionado com sucesso no contrato");
            this.updateResourceList("accs", response);
            this.clear();
          })
          .catch(() => {
            this.$alertError("Houve um problema para adicionar o ACC");
          });
      }
    },
    initialize() {
      this.acc = Object.assign({}, this.defaultAcc);
      this.acc.currency_id = this.selectedOpositeContractCurrency;
    },
    edit(item) {
      this.acc = Object.assign({}, item);
    },
    confirmDelete(dndf) {
      this.setResource("tradeslip_exchange_acc");
      this.delete(dndf.id)
        .then(async () => {
          this.$alertSuccess("ACC removido com sucesso!");
          // Faz o busca pq pode ter atualzação de ref
          this.removeFromResourceList("accs", dndf);
        })
        .catch(() => {
          this.$alertError("Houve um problema ao remover o ACC");
        });
    },
    manualInputDate(date, prop) {
      this.acc[prop] = date;
    },
    isDollarContract(currency_id) {
      return this.currencies.find((c) => c.id === currency_id).identifier ===
        "D"
        ? true
        : false;
    },
  },
  created() {
    this.initialize();
  },
};
</script>

<style>
</style>