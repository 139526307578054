
import Vue from 'vue';

export default Vue.extend({
  props: {
    title: {
      type: String,
      default: "Resultado"
    },
    position: {
      type: String,
      default: "SALE"
    },
    short: {
      type: [Number, String],
      default: 0
    },
    long: {
      type: [Number, String],
      default: 0
    },
    result: {
      type: [Number, String],
      default: 0
    },
    currencySymbol: {
      type: String,
      default: "R$"
    },
    currencyDecimal: {
      type: Number,
      default: 2
    },
  },
});
