<template>
  <v-card class="overflow-hidden">
    <v-app-bar
      color="primary"
      dense
      dark>
      <v-app-bar-nav-icon ></v-app-bar-nav-icon>
      <v-app-bar-title>Tipo de Operações</v-app-bar-title>
      <v-spacer></v-spacer>

      <template v-slot:extension>
        <v-tabs v-model="tab" align-with-title>
          <v-tab>ACC</v-tab>
          <v-tab>NDF</v-tab>
          <v-tab>Trava</v-tab>
          <v-tab>Contratos Futuros B3</v-tab>
        </v-tabs>
      </template>
    </v-app-bar>
    <v-sheet>
      <v-container fluid>
        <v-tabs-items v-model="tab" class="pt-5">
          <v-tab-item>
            <ACC
              :contract="contract"
              :gColor="gColor"/>
          </v-tab-item>
          <v-tab-item>
            <NDF
              :contract="contract"
              :gColor="gColor"/>
          </v-tab-item>
          <v-tab-item>
            <ExportLock
              :contract="contract"
              :gColor="gColor"/>
          </v-tab-item>
          <v-tab-item>
            <DollarFutures
              :contract="contract"
              :gColor="gColor"/>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </v-sheet>
  </v-card>
</template>

<script>
import ACC from "./exchange_types/ACC";
import NDF from "./exchange_types/NDF";
import ExportLock from "./exchange_types/ExportLock";
import DollarFutures from "./exchange_types/DollarFutures";

import { mapActions } from "vuex";

export default {
  props: {
    contract: Object,
    gColor: String,
  },
  components: {
    ACC,
    NDF,
    ExportLock,
    DollarFutures
  },
  data() {
    return {
      tab: null,
    }
  },
  methods: {
    ...mapActions({
      loadAllExchanges: "EXCHANGE_LOAD",
    }),
  },
  created() {
    this.loadAllExchanges(this.contract.id);
  },
}
</script>

<style>

</style>