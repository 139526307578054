<template>
  <base-material-card color="primary" class="py-1">
    <template v-slot:heading>
      <div class="text-center font-weight-bold">
        <h1 class="text-h3 white--text text-uppercase font-weight-bold">
          Contrato boletador
        </h1>
      </div>
    </template>

    <v-card color flat class="px-4 mt-2">
      <v-row v-if="posloading">
        <v-col cols="12">
          <v-skeleton-loader
            v-bind="crudSkelletonAttrs"
            type="article, paragraph@2, actions"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <div v-else>
        <v-scroll-y-transition group tag="div">
          <v-row v-if="showContractForm" :key="1">
            <v-col>
              <contract-form
                ref="contractForm"
                :contractEdit="contractToEdit"
                :gColor="gColor"
                @cancel="cancel"
              />
            </v-col>
          </v-row>
          <v-row v-else :key="2">
            <v-col>
              <contract-list
                ref="contractList"
                :gColor="gColor"
                @open="open"
                @edit="edit"
              />
            </v-col>
          </v-row>
        </v-scroll-y-transition>
      </div>
    </v-card>
  </base-material-card>
</template>

<script>
import { tradeslip } from "@/mixins/tradeslip";
import { mapActions } from "vuex";

import ContractList from "./ContractList";
import ContractForm from "./ContractForm";

export default {
  name: "TradeslipContract",
  mixins: [tradeslip],
  components: {
    ContractList,
    ContractForm,
  },
  data() {
    return {
      gColor: undefined,
      theme: undefined,
      contractToEdit: undefined,
      providers: [],
      brokerageFirms: [],
      incoterms: [],
      showContractForm: false,
      posloading: false,
    };
  },

  methods: {
    ...mapActions({
      preLoad: "PRE_LOAD",
      posLoad: "POS_LOAD",
    }),
    getResorces() {
      let payload = {
        resource: "provider",
        params: {
          my: true,
          checkDelete: true,
        },
      };
      // Forncedores/clientes/vendedor
      this.getResource(payload).then((result) => {
        this.providers = result;
        this.brokerageFirms = result.filter((r) => r.service === "BROKER");

        payload.resource = "incoterm";
        payload.params = { limit: 0 };
        this.getResource(payload).then((result) => {
          this.incoterms = result;
        });
      });
    },
    getThemeColors() {
      let theme = null;
      if (this.$vuetify.theme.isDark) {
        theme = this.$vuetify.theme.themes.dark;
      } else {
        theme = this.$vuetify.theme.themes.light;
      }

      return theme;
    },
    open() {
      this.showContractForm = true;
    },
    cancel() {
      this.showContractForm = false;
      this.contractToEdit = undefined;
    },
    async edit(item) {
      this.contractToEdit = Object.assign({}, item);
      this.posloading = true;
      await this.posLoad(this.contractToEdit.id);
      this.posloading = false;
      this.open();
    },
  },

  created() {
    this.theme = this.getThemeColors();
    this.gColor = this.theme.primary;
    this.preLoad();
  },
};
</script>

<style>
</style>