<template>
  <div>
    <v-row>
      <v-col class="text-center">
        <span class="text-h3 primary--text font-weight-medium">Trava de Exportação</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-form ref="mainForm">
          <v-row>
            <v-col cols="auto">
              <v-autocomplete
                v-model="exportLock.bank_id"
                label="Banco*"
                :items="banks"
                item-text="name"
                item-value="id"
                :color="gColor"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
                required/>
            </v-col>
            <v-col cols="auto">
              <v-text-field
                v-model="exportLock.contract"
                :color="gColor"
                :rules="[rules.required]"
                label="Contrato*"
                outlined
                dense
                required/>
            </v-col>
            <v-col cols="auto">
              <agr-date-picker
                v-model="exportLock.contract_date"
                label="Contratação*"
                :color="gColor"
                outlined
                dense
                v-on:change="manualInputDate($event, 'contract_date')"/>
            </v-col>
            <v-col cols="auto">
              <agr-date-picker
                v-model="exportLock.due_date"
                label="Vencimento*"
                :color="gColor"
                outlined
                dense
                v-on:change="manualInputDate($event, 'due_date')"/>
            </v-col>
            <v-col cols="1">
              <v-text-field
                :value="contractPeriod"
                :color="gColor"
                label="Prazo*"
                outlined
                dense
                readonly/>
            </v-col>
            <v-col cols="auto">
              <v-currency-field
                v-model="exportLock.notional"
                label="Montante*"
                :color="gColor"
                :rules="[rules.required]"
                :prefix="suffixSelectedCurrency.prefix"
                :locale="suffixSelectedCurrency.locale"
                outlined
                dense/>
            </v-col>
            <v-col cols="1">
              <v-autocomplete
                v-model="exportLock.currency_id"
                label="Moeda*"
                :items="currencies"
                item-text="name"
                item-value="id"
                :color="gColor"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
                required/>
            </v-col>
            <v-col cols="1">
              <v-currency-field
                v-model="exportLock.fee"
                label="Taxa*"
                :color="gColor"
                :rules="[rules.required]"
                :prefix="suffixOpositeCurrency.prefix"
                :locale="suffixOpositeCurrency.locale"
                :decimalLength="4"
                outlined
                dense/>
            </v-col>
            <v-col cols="auto">
              <v-currency-field
                :value="notionalConverted"
                label="Montante"
                :color="gColor"
                :prefix="suffixOpositeCurrency.prefix"
                :locale="suffixOpositeCurrency.locale"
                outlined
                dense
                readonly/>
            </v-col>
            <v-col cols="1">
              <v-currency-field
                v-model="exportLock.prize"
                label="Prêmio*"
                :color="gColor"
                :rules="[rules.required]"
                :prefix="suffixOpositeCurrency.prefix"
                :locale="suffixOpositeCurrency.locale"
                suffix="%"
                outlined
                dense/>
            </v-col>
            <v-col cols="auto">
              <v-currency-field
                :value="nominalPrize"
                label="Prêmio"
                :color="gColor"
                :prefix="suffixOpositeCurrency.prefix"
                :locale="suffixOpositeCurrency.locale"
                outlined
                dense
                readonly/>
            </v-col>
            <v-col cols="auto">
              <v-currency-field
                :value="taxes"
                label="Imposto de Renda"
                :color="gColor"
                :prefix="suffixOpositeCurrency.prefix"
                :locale="suffixOpositeCurrency.locale"
                outlined
                dense
                readonly/>
            </v-col>
            <v-col cols="auto">
              <v-currency-field
                :value="credits"
                label="Crédito"
                :color="gColor"
                :prefix="suffixOpositeCurrency.prefix"
                :locale="suffixOpositeCurrency.locale"
                outlined
                dense
                readonly/>
            </v-col>
            <v-col cols="auto">
              <v-btn
                color="primary"
                @click="save">
                <v-icon left>
                  {{ exportLock.id ? 'mdi-autorenew' : 'mdi-plus' }}
                </v-icon>
                {{ exportLock.id ? 'Atualizar' : 'Adicionar' }}
              </v-btn>
            </v-col>
            <v-col cols="auto" v-if="exportLock.id">
              <v-btn
                color="red"
                @click="clear">
                <v-icon left>
                  mdi-close
                </v-icon>
                Cancelar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-data-table
        :headers="headers"
        :items="exportLocks"
        :items-per-page="10"
        :loading="quering"
        sort-by="id"
        class="elevation-0 table-full-width"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-arrow-left',
          nextIcon: 'mdi-arrow-right'
        }">
        <template v-slot:[`header.contract`]="{ header }">
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
            </template>
            <span>{{ header.tooltip }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`header.contract_date`]="{ header }">
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
            </template>
            <span>{{ header.tooltip }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`header.due_date`]="{ header }">
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
            </template>
            <span>{{ header.tooltip }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`header.notional`]="{ header }">
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
            </template>
            <span>{{ header.tooltip }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`header.fee`]="{ header }">
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
            </template>
            <span>{{ header.tooltip }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`header.notionalConverted`]="{ header }">
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
            </template>
            <span>{{ header.tooltip }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`header.actions`]="{ header }">
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
            </template>
            <span>{{ header.tooltip }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.contract`]="{ item }">
          <span class="font-weight-medium">{{ item.contract }}</span>
        </template>
        <template v-slot:[`item.contract_date`]="{ item }">
          <span>{{ item.contract_date | formatterBRZDate }}</span>
        </template>
        <template v-slot:[`item.due_date`]="{ item }">
          <span v-if="item.isSettled">{{ item.settled_date | formatterBRZDate }}</span>
          <span v-else>{{ item.due_date | formatterBRZDate }}</span>
        </template>
        <template v-slot:[`item.notional`]="{ item }">
          <span v-if="isDollarContract(item.currency_id)">{{ item.notional | formatterDollar }}</span>
          <span v-else>{{ item.notional | formatterReal }}</span>
        </template>
        <template v-slot:[`item.fee`]="{ item }">
          <span v-if="isDollarContract(item.currency_id)">R$ {{ item.fee | formatterUnit(4) }}</span>
          <span v-else>{{ item.fee | formatterDollar }}</span>
        </template>
        <template v-slot:[`item.notionalConverted`]="{ item }">
          <span v-if="isDollarContract(item.currency_id)">{{ (item.notional * item.fee) | formatterReal }}</span>
          <span v-else>{{ (item.notional/item.fee) | formatterDollar }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-row no-gutters justify=center>
            <v-col cols="auto">
              <agr-actions-buttons-table
                :item="item"
                settled
                extra-btn
                extra-text="Detalhes"
                extra-icon="mdi-cash-check"
                @edit="edit"
                @confirmDelete="confirmDelete"
                @toSettle="openSettled"
                @extra="openDetails"/>
            </v-col>
          </v-row>
        </template>
        <template v-slot:no-data>
          <v-container
            fluid
            fill-height
            style="background-color: rgba(255, 255, 255, 0.5);"
          >
            <v-layout justify-center align-center>
              <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
              <strong
                v-else
                class="py-2"
                style="font-color:red;font-size:14px"
              >Nenhuma Trava encontrada para este contrato</strong>
            </v-layout>
          </v-container>
        </template>
      </v-data-table>
    </v-row>
    <v-dialog
      v-model="dialogDetails"
      mix-height="100vh"
      max-width="40vw"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      @keydown.esc="closeDetails">
      <v-card pt-3>
        <v-card-title class="form-header headline pa-2 font-weight-medium">Trava - Detalhamento</v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headersDetails"
            :items="details"
            :items-per-page="10"
            class="elevation-0 table-full-width"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-arrow-left',
              nextIcon: 'mdi-arrow-right'
            }">
            <template v-slot:[`header.date`]="{ header }">
              <v-tooltip top dark>
                <template v-slot:activator="{ on }">
                  <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
                </template>
                <span>{{ header.tooltip }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`header.prize`]="{ header }">
              <v-tooltip top dark>
                <template v-slot:activator="{ on }">
                  <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
                </template>
                <span>{{ header.tooltip }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`header.cumulate`]="{ header }">
              <v-tooltip top dark>
                <template v-slot:activator="{ on }">
                  <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
                </template>
                <span>{{ header.tooltip }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.date`]="{ item }">
              <span class="font-weight-medium">{{ item.date }}</span>
            </template>
            <template v-slot:[`item.prize`]="{ item }">
              <span>{{ item.prize | formatterReal }}</span>
            </template>
            <template v-slot:[`item.cumulate`]="{ item }">
              <span>{{ item.cumulate | formatterReal }}</span>
            </template>
          </v-data-table>
          <v-divider class="mb-6"/>
          <v-row justify="center"><span class="text-h4 font-weight-bold">Até o momento</span></v-row>
          <v-row v-if="this.exportLockSettled">
            <v-col class="text-center">
              <div><span>Valor acumulado</span></div>
              <div><span>{{ this.cumulateTillNow | formatterReal }}</span></div>
            </v-col>
            <v-col class="text-center">
              <div><span>Imposto de Renda</span></div>
              <div><span>{{ this.taxTillNow | formatterReal }}</span></div>
            </v-col>
            <v-col class="text-center">
              <div><span>Vencimento</span></div>
              <div><span>{{ this.exportLockSettled.due_date | formatterBRZDate }}</span></div>
            </v-col>
            <v-col class="text-center">
              <div><span>Liquidação</span></div>
              <div v-if="this.exportLockSettled.isSettled"><span>{{ this.exportLockSettled.settled_date | formatterBRZDate }}</span></div>
              <div v-else>Aguardando liquidação</div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row justify="end" no-gutters>
            <v-col class="text-right firefox-align-end">
              <v-btn
                color="accent"
                @click="closeDetails">
                Fechar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog"
      mix-height="100vh"
      max-width="40vw"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      @keydown.esc="closeSettled">
      <v-card pt-3>
        <v-card-title class="form-header headline pa-2 font-weight-medium">Trava de Exportação - Liquidação</v-card-title>
        <v-card-text>
          <v-row v-if="exportLockSettled.isSettled">
            <v-col cols="12" align="center">
              <v-row justify=center>
                <v-col cols="auto">
                  <v-alert
                    border="left"
                    colored-border
                    type="success"
                    elevation="1"
                    class="py-1 px-2">
                    <span class="text-h4 font-weight-light">
                      Trava já liquidada
                    </span>
                  </v-alert>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-form ref="mainForm">
              <v-row>
                <v-col cols="4">
                  <v-currency-field
                    v-model="exportLockSettled.notional"
                    label="Montante"
                    :color="gColor"
                    :prefix="settledCurrency.prefix"
                    :locale="settledCurrency.locale"
                    outlined
                    dense
                    readonly/>
                </v-col>
                <v-col cols="4">
                  <v-currency-field
                    v-model="exportLockSettled.fee"
                    label="Taxa"
                    :color="gColor"
                    :prefix="settledOpositeCurrency.prefix"
                    :locale="settledOpositeCurrency.locale"
                    :decimalLength="4"
                    outlined
                    dense
                    readonly/>
                </v-col>
                <v-col cols="4">
                  <v-currency-field
                    :value="creditTillNow"
                    label="Crédito"
                    :color="gColor"
                    :prefix="settledOpositeCurrency.prefix"
                    :locale="settledOpositeCurrency.locale"
                    outlined
                    dense
                    readonly/>
                </v-col>
              </v-row>
              <v-row >
                <v-col cols="4">
                  <agr-date-picker
                    v-model="exportLockSettled.settled_date"
                    label="Liquidação"
                    :color="gColor"
                    outlined
                    dense
                    v-on:change="manualInputDateSettled($event, 'settled_date')"/>
                </v-col>
                <v-col cols="4">
                  <v-currency-field
                    :value="cumulateTillNow"
                    label="Prêmio"
                    :color="gColor"
                    :prefix="settledOpositeCurrency.prefix"
                    :locale="settledOpositeCurrency.locale"
                    outlined
                    dense
                    readonly/>
                </v-col>
                <v-col cols="4">
                  <v-currency-field
                    :value="taxTillNow"
                    label="Imposto de Renda"
                    :color="gColor"
                    :prefix="settledOpositeCurrency.prefix"
                    :locale="settledOpositeCurrency.locale"
                    outlined
                    dense
                    readonly/>
                </v-col>
              </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row justify="end" no-gutters>
            <v-col class="text-right firefox-align-end">
              <v-btn
                color="accent"
                @click="closeSettled">
                {{ exportLockSettled.isSettled ? 'Fechar' : 'Cancelar' }}
              </v-btn>
              <v-btn
                v-if="!exportLockSettled.isSettled"
                color="primary"
                @click="settled"
              >
                Liquidar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { tradeslip } from "@/mixins/tradeslip";
import { mapGetters, mapActions } from "vuex";
import { decimalBRZToNumber } from '@/utilsNumber';

export default {
  name: "ExportLock",
  mixins: [tradeslip],
  props: {
    contract: Object,
    gColor: String,
  },
  data() {
    return {
      exportLock: undefined,
      exportLockSettled: undefined,
      dialog: false,
      dialogDetails: false,
      rulesDelivery: {
        maxAmountDelivery: (value) => {
          if (!this.contractDelivery || !value) return true;
          return (
            decimalBRZToNumber(value) <=
              parseFloat(this.contractDelivery.amount) ||
            "Valor superior a quantidade"
          );
        },
      },
      headers: [
        {
          text: "Contrato",
          tooltip: "Referência contrato da Trava",
          align: "left",
          value: "contract",
        },
        {
          text: "Contratação",
          tooltip: "Data da contratação",
          align: "center",
          value: "contract_date",
        },
        {
          text: "Liquidação",
          tooltip: "Data de liquidação do contrato",
          align: "center",
          value: "due_date",
        },
        {
          text: "Montante",
          tooltip: "Valor contratado",
          align: "center",
          value: "notional",
        },
        {
          text: "Taxa",
          tooltip: "Taxa acordada",
          align: "center",
          value: "fee",
        },
        {
          text: "Montante",
          tooltip: "Valor contratado",
          align: "center",
          value: "notionalConverted",
        },
        {
          text: "Ações",
          align: "center",
          tooltip: "Modificar ou excluir o contrato da Trava",
          value: "actions",
          sortable: false,
        },
      ],
      headersDetails: [
        {
          text: "Período",
          tooltip: "mês de referência",
          align: "left",
          value: "date",
        },
        {
          text: "Resultado",
          tooltip: "Prêmio do mês",
          align: "center",
          value: "prize",
        },
        {
          text: "Acumulado",
          tooltip: "Valor acumulado do prêmio",
          align: "center",
          value: "cumulate",
        },
      ],
      defaultExportLock: {
        contract: undefined,
        contract_date: undefined,
        due_date: undefined,
        notional: undefined,
        fee: undefined,
        prize: undefined,
        isSettled: false,
        settled_date: undefined,
        settled_prize: undefined,
        settled_tax: undefined,
        settled_credit: undefined,
        currency_id: undefined,
        bank_id: undefined,
        tradeslip_id: undefined,
      },
      dollarConfig: {
        prefix: "$",
        locale: "en-US",
      },
      realConfig: {
        prefix: "R$",
        locale: "pt-BR",
      },
    };
  },
  computed: {
    ...mapGetters({
      exportLocks: "getExportLocks",
      banks: "getBanks",
      quering: "getQuering",
    }),
    contractPeriod() {
      if (this.exportLock.contract_date && this.exportLock.due_date) {
        const date = this.$luxon(this.exportLock.contract_date);
        const due = this.$luxon(this.exportLock.due_date);
        let diff = due.diff(date, "days").toObject();
        return diff.days;
      }
      return 0;
    },
    selectedOpositeContractCurrency() {
      let currency = this.currencies.filter(
        (c) => c.id !== this.contract.currency_id
      );
      return currency.length > 0 ? currency[0].id : undefined;
    },
    currencySelected() {
      if (this.exportLock.currency_id) {
        return this.currencies.find(
          (c) => c.id === this.exportLock.currency_id
        );
      }
      return undefined;
    },
    notionalConverted() {
      if (
        this.exportLock.fee &&
        this.exportLock.notional &&
        this.currencySelected
      ) {
        let notional = parseFloat(this.exportLock.notional);
        let fee = parseFloat(this.exportLock.fee);
        if (this.currencySelected.identifier === "D") {
          return notional * fee;
        } else {
          return notional / fee;
        }
      }
      return 0;
    },
    suffixSelectedCurrency() {
      if (this.currencySelected.identifier === "D") {
        return this.dollarConfig;
      }
      return this.realConfig;
    },
    suffixOpositeCurrency() {
      if (this.currencySelected.identifier === "D") {
        return this.realConfig;
      }
      return this.dollarConfig;
    },
    nominalPrize() {
      if (this.exportLock.prize && this.notionalConverted) {
        // return this.notionalConverted * (parseFloat(this.exportLock.prize)/100)
        let am = parseFloat(this.exportLock.prize) / 100 / 12;
        let months = this.contractPeriod / 30;
        if (months % 1) {
          months = months + 1;
        }
        let cumulate = 0;
        for (let i = 0; i < parseInt(months); i++) {
          cumulate =
            cumulate +
            parseFloat(this.exportLock.notional) *
              parseFloat(this.exportLock.fee) *
              am;
        }
        return cumulate;
      }
      return 0;
    },
    taxes() {
      if (this.nominalPrize && this.contractPeriod) {
        return this.nominalPrize * (this.getAliquot(this.contractPeriod) / 100);
      }
      return 0;
    },
    credits() {
      if (this.nominalPrize && this.notionalConverted && this.taxes) {
        return this.notionalConverted + this.nominalPrize - this.taxes;
      }
      return 0;
    },
    // Settled props #####
    settledCurrency() {
      if (this.exportLockSettled && this.exportLockSettled.currency_id) {
        if (
          this.currencies.find(
            (c) => c.id === this.exportLockSettled.currency_id
          ).identifier === "D"
        ) {
          return this.dollarConfig;
        }
        return this.realConfig;
      }
      return this.realConfig;
    },
    settledOpositeCurrency() {
      if (this.exportLockSettled && this.exportLockSettled.currency_id) {
        if (
          this.currencies.find(
            (c) => c.id === this.exportLockSettled.currency_id
          ).identifier === "D"
        ) {
          return this.realConfig;
        }
        return this.dollarConfig;
      }
      return this.dollarConfig;
    },
    settledPeriod() {
      if (this.exportLockSettled && this.exportLockSettled.settled_date) {
        const date = this.$luxon(this.exportLockSettled.contract_date);
        const settled = this.$luxon(this.exportLockSettled.settled_date);
        let diff = settled.diff(date, "days").toObject();
        return diff.days;
      }
      return 0;
    },
    currencyInSettled() {
      if (this.exportLockSettled.currency_id) {
        return this.currencies.find(
          (c) => c.id === this.exportLockSettled.currency_id
        );
      }
      return undefined;
    },
    notionalConvertedSettled() {
      if (
        this.exportLockSettled.fee &&
        this.exportLockSettled.notional &&
        this.currencySelected
      ) {
        let notional = parseFloat(this.exportLockSettled.notional);
        let fee = parseFloat(this.exportLockSettled.fee);
        if (this.currencySelected.identifier === "D") {
          return notional * fee;
        } else {
          return notional / fee;
        }
      }
      return 0;
    },
    // Lock Detials
    details() {
      if (this.exportLockSettled) {
        let am = parseFloat(this.exportLockSettled.prize) / 100 / 12;
        const date = this.$luxon(this.exportLockSettled.contract_date);
        const due = this.$luxon(this.exportLockSettled.due_date);
        let diff = due.diff(date, "months").toObject();
        if (diff.months % 1) {
          diff.months = diff.months + 1;
        }
        let range = date;
        let result = [];
        let cumulate = 0;
        for (let i = 0; i < parseInt(diff.months); i++) {
          let cPrize =
            parseFloat(this.exportLockSettled.notional) *
            parseFloat(this.exportLockSettled.fee) *
            am;
          cumulate = cumulate + cPrize;
          result.push({
            date: `${range.monthLong}/${range.year}`,
            prize: cPrize.toFixed(2),
            cumulate: cumulate.toFixed(2),
          });
          range = range.plus({ months: 1 });
        }
        return result;
      }
      return [];
    },
    periodTillNow() {
      if (this.exportLockSettled) {
        const date = this.$luxon(this.exportLockSettled.contract_date);
        const today = this.$luxon(new Date().toISOString().substr(0, 10));
        let diff = today.diff(date, "months").toObject();
        if (diff.months % 1) {
          diff.months = diff.months + 1;
        }
        return parseInt(diff.months);
      }
      return 0;
    },
    cumulateTillNow() {
      if (this.exportLockSettled && this.periodTillNow) {
        let am = parseFloat(this.exportLockSettled.prize) / 100 / 12;

        let cumulate = 0;
        for (let i = 0; i < this.periodTillNow; i++) {
          cumulate =
            cumulate +
            parseFloat(this.exportLockSettled.notional) *
              parseFloat(this.exportLockSettled.fee) *
              am;
        }
        return cumulate;
      }
      return 0;
    },
    taxTillNow() {
      if (this.periodTillNow && this.cumulateTillNow) {
        return (
          this.cumulateTillNow * (this.getAliquot(this.periodTillNow) / 100)
        );
      }
      return 0;
    },
    creditTillNow() {
      if (
        this.notionalConvertedSettled &&
        this.cumulateTillNow &&
        this.taxTillNow
      ) {
        return (
          this.notionalConvertedSettled + this.cumulateTillNow - this.taxTillNow
        );
      }
      return 0;
    },
  },
  methods: {
    ...mapActions({
      reloadExchangeAcc: "SEARCH_TRADESLIP_EXCHANGE_ACC",
    }),
    save() {
      if (this.validate()) {
        this.exportLock.tradeslip_id = this.contract.id;
        // Verifica se uma data foi selecionada
        // Senão utiliza a padrao
        this.exportLock.contract_date = this.dateISONotNull(
          this.exportLock.contract_date
        );
        this.exportLock.due_date = this.dateISONotNull(
          this.exportLock.due_date
        );

        this.setResource("tradeslip_exchange_export_lock");
        this.saveOrUpdate(this.exportLock)
          .then((response) => {
            this.$alertSuccess("Trava adicionada com sucesso no contrato");
            this.updateResourceList("exportLocks", response);
            this.clear();
          })
          .catch(() => {
            this.$alertError("Houve um problema para adicionar a Trava");
          });
      }
    },
    initialize() {
      this.exportLock = Object.assign({}, this.defaultExportLock);
      this.exportLockSettled = Object.assign({}, this.defaultExportLock);
      this.exportLock.currency_id = this.selectedOpositeContractCurrency;
    },
    edit(item) {
      this.exportLock = Object.assign({}, item);
    },
    confirmDelete(dexportLock) {
      this.setResource("tradeslip_exchange_export_lock");
      this.delete(dexportLock.id)
        .then(async () => {
          this.$alertSuccess("Trava removida com sucesso!");
          // Faz o busca pq pode ter atualzação de ref
          this.removeFromResourceList("exportLocks", dexportLock);
        })
        .catch(() => {
          this.$alertError("Houve um problema ao remover a Trava");
        });
    },
    manualInputDate(date, prop) {
      this.exportLock[prop] = date;
    },
    openSettled(item) {
      this.dialog = true;
      this.$nextTick(() => {
        this.exportLockSettled = Object.assign({}, item);
        if (!this.exportLockSettled.isSettled)
          this.exportLockSettled.settled_date = this.$luxon(
            new Date().toISOString().substr(0, 10)
          ).toString();
      });
    },
    openDetails(item) {
      this.dialogDetails = true;
      this.$nextTick(() => {
        this.exportLockSettled = Object.assign({}, item);
      });
    },
    closeSettled() {
      this.dialog = false;
      this.$nextTick(() => {
        this.exportLockSettled = Object.assign({}, this.defaultExportLock);
      });
    },
    closeDetails() {
      this.dialogDetails = false;
      this.$nextTick(() => {
        this.exportLockSettled = Object.assign({}, this.defaultExportLock);
      });
    },
    getAliquot(period) {
      if (period < 180) return 22.5;
      if (period > 180 && period < 360) return 20;
      if (period > 360 && period < 720) return 17.5;
      if (period > 721) return 15;
      else return 1;
    },
    isDollarContract(currency_id) {
      return this.currencies.find((c) => c.id === currency_id).identifier ===
        "D"
        ? true
        : false;
    },
    settled() {
      this.exportLockSettled.settled_date = this.$luxon(
        this.exportLockSettled.settled_date
      ).toString();
      this.exportLockSettled.isSettled = true;
      this.exportLockSettled.settled_prize = this.cumulateTillNow;
      this.exportLockSettled.settled_tax = this.taxTillNow;
      this.exportLockSettled.settled_credit = this.taxTillNow;

      this.setResource("tradeslip_exchange_export_lock");
      this.saveOrUpdate(this.exportLockSettled)
        .then((response) => {
          this.$alertSuccess("Trava liquidada");
          this.updateResourceList("exportLocks", response);
          this.reloadExchangeAcc(this.contract.id);
          this.initialize();
          this.reset();
          this.closeSettled();
        })
        .catch(() => {
          this.$alertError("Houve um problema ao liquidar a Trava");
        });
    },
    manualInputDateSettled(date, prop) {
      this.exportLockSettled[prop] = date;
    },
    unsettled() {
      this.exportLockSettled.isSettled = false;
      this.exportLockSettled.settled_date = null;
      this.exportLockSettled.settled_fee = null;
      this.exportLockSettled.settled_interest = null;
      this.exportLockSettled.profitLoss = null;

      this.setResource("tradeslip_exchange_export_lock");
      this.saveOrUpdate(this.exportLockSettled)
        .then((response) => {
          this.$alertSuccess("Liquidação desfeita!");
          this.updateResourceList("exportLocks", response);
          this.reset();
          this.closeSettled();
        })
        .catch(() => {
          this.$alertError("Houve um problema ao desfazer a liquidação");
        });
    },
  },

  created() {
    this.initialize();
  },
};
</script>

<style>
</style>