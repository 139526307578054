<template>
  <div>
    <v-scroll-y-transition group  tag="div">
      <v-row :key="1">
        <v-col class="text-h4 text-left font-weight-bold">Formação de Preço</v-col>
      </v-row>
      <v-row :key="2" dense>
        <v-col cols="12" md="8" lg="6" xl="5">
          <v-alert
            outlined
            :type="alertStatus"
            text
          >
            <div class="font-weight-medium" v-html="alertHtml"/>
          </v-alert>
        </v-col>
      </v-row>
      <v-row :key="3" class="mt-n2">
        <v-col>
          <v-form ref="mainForm">
            <v-row>
              <v-col cols="auto">
                <v-autocomplete
                  v-model="contractPricing.tradeslip_delivery_period_id"
                  label="Período*"
                  :items="contractTradeslipDeliveries"
                  item-text="ref_period"
                  item-value="id"
                  :color="gColor"
                  :rules="[rules.required]"
                  outlined
                  dense
                  clearable/>
              </v-col>
              <v-col cols="auto">
                <v-currency-field
                  v-model="contractPricing.amount"
                  label="Quantidade*"
                  :color="gColor"
                  :rules="[rules.required, rules.nozero, rules.maxAmount, rules.sumResourceMaxAmount, rulesPricing.maxAmountDelivery]"
                  validate-on-blur
                  :suffix="contract.unit.symbol"
                  outlined
                  dense/>
              </v-col>
              <v-col cols="auto">
                <v-btn
                  color="primary"
                  @click="save">
                  <v-icon left>
                    {{ contractPricing.id ? 'mdi-autorenew' : 'mdi-plus' }}
                  </v-icon>
                  {{ contractPricing.id ? 'Atualizar' : 'Adicionar' }}
                </v-btn>
              </v-col>
              <v-col cols="auto" v-if="contractPricing.id">
                <v-btn
                  color="red"
                  @click="clear">
                  <v-icon left>
                    mdi-close
                  </v-icon>
                  Cancelar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
      <v-row :key="4">
        <v-skeleton-loader
          v-if="quering || load"
          v-bind="crudSkelletonAttrs"
          type="table-heading@2, list-item-two-line"
          width="100%"/>

        <v-data-table
          v-else
          :headers="headersPricing"
          :items="contractPricings"
          :items-per-page="5"
          :loading="quering || load"
          sort-by="id"
          class="elevation-0 table-full-width"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-arrow-left',
            nextIcon: 'mdi-arrow-right'
          }"
          show-select
          single-select
          :value="contractPricingSelected"
          @click:row="setToFixing"
          @item-selected="setToFixingCheckBox">
          <template v-slot:[`header.selected`]="{ header }">
            <v-tooltip top dark>
              <template v-slot:activator="{ on }">
                <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
              </template>
              <span>{{ header.tooltip }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`header.tradeslip_delivery_period.ref_period`]="{ header }">
            <v-tooltip top dark>
              <template v-slot:activator="{ on }">
                <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
              </template>
              <span>{{ header.tooltip }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`header.amount`]="{ header }">
            <v-tooltip top dark>
              <template v-slot:activator="{ on }">
                <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
              </template>
              <span>{{ header.tooltip }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`header.dollar`]="{ header }">
            <v-tooltip top dark>
              <template v-slot:activator="{ on }">
                <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
              </template>
              <span>{{ header.tooltip }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`header.detailed_price`]="{ header }">
            <v-tooltip top dark>
              <template v-slot:activator="{ on }">
                <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
              </template>
              <span>{{ header.tooltip }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`header.actions`]="{ header }">
            <v-tooltip top dark>
              <template v-slot:activator="{ on }">
                <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
              </template>
              <span>{{ header.tooltip }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.tradeslip_delivery_period.ref_period`]="{ item }">
            <span class="font-weight-medium">{{ item.tradeslip_delivery_period.ref_period }}</span>
          </template>
          <template v-slot:[`item.amount`]="{ item }">
            <span>{{ item.amount | formatterUnit(2) }} {{ contract.unit.symbol }}</span>
          </template>
          <template v-slot:[`item.dollar`]="{ item }">
            <span>{{ item.dollar | formatterReal }}</span>
          </template>
          <template v-slot:[`item.detailed_price`]="{ item }">
            <span>{{ item.detailed_price }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <agr-actions-buttons-table
              :item="item"
              @edit="edit"
              @confirmDelete="confirmDelete"/>
          </template>
          <template v-slot:no-data>
            <v-container
              fluid
              fill-height
              style="background-color: rgba(255, 255, 255, 0.5);"
            >
              <v-layout justify-center align-center>
                <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
                <strong
                  v-else
                  class="py-2"
                  style="font-color:red;font-size:14px"
                >Nenhuma formação de preço encontrada para este contrato</strong>
              </v-layout>
            </v-container>
          </template>
        </v-data-table>
      </v-row>
      <!-- ################################# -->
      <!-- Tabela com componentes da precificação -->
      <!-- ################################# -->
      <div :key="5" v-if="contractPricingToFixing">
        <v-row justify="space-between">
          <v-col cols="6" class="text-h4 text-left font-weight-bold">Fixações para o período {{ contractPricingToFixing.tradeslip_delivery_period.ref_period }}</v-col>
          <v-col cols=2 class="text-end">
            <v-btn
              color="red"
              rounded
              small
              @click="closeDetails">
              <v-icon left>
                mdi-close
              </v-icon>
              Fechar
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-form ref="detailsForm">
              <v-row>
                <v-col cols="10">
                  <v-row dense>
                    <v-col cols="2">
                      <v-autocomplete
                        v-model="contractPricingDetail.tradeslip_pricing_component_id"
                        label="Componente*"
                        :items="pricingComponentsFiltered"
                        item-text="name"
                        item-value="id"
                        :color="gColor"
                        outlined
                        dense
                        clearable
                        :disabled="loadPricingDetailsActions || hasFlatComponent"
                        @change="configComponent"/>
                    </v-col>
                    <v-col cols="1">
                      <v-autocomplete
                        v-model="contractPricingDetail.security_ref"
                        label="Referência*"
                        :items="stockContracts"
                        :color="gColor"
                        :menu-props="{maxHeight: 250}"
                        outlined
                        dense
                        :disabled="loadPricingDetailsActions || hasFlatComponent"
                        clearable/>
                    </v-col>
                    <v-col cols="2">
                      <agr-date-picker
                        v-model="contractPricingDetail.date"
                        label="Data Fixação*"
                        :color="gColor"
                        outlined
                        dense
                        :disabled="loadPricingDetailsActions || hasFlatComponent"
                        v-on:change="manualInputDateDetails($event, 'date')"/>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-if="componentSelected && componentSelected.identifier === 'se'"
                        v-model="contractPricingDetail.amount"
                        label="Quantidade de contratos*"
                        :color="gColor"
                        :rules="[rules.maxAmount]"
                        type="number"
                        outlined
                        :disabled="loadPricingDetailsActions || hasFlatComponent"
                        dense/>
                      <v-currency-field
                        v-else
                        v-model="contractPricingDetail.amount"
                        label="Quantidade*"
                        :color="gColor"
                        :rules="[rules.nozero, rules.maxAmount, rulesDetailPricing.sumMaxAmountPricing, rulesDetailPricing.sumMaxAmountComponent]"
                        validate-on-blur
                        :suffix="contract.unit.symbol"
                        :disabled="loadPricingDetailsActions || hasFlatComponent"
                        outlined
                        dense/>
                    </v-col>
                    <v-col cols="2">
                      <v-autocomplete
                        v-model="contractPricingDetail.unit_id"
                        label="Medida*"
                        :items="units"
                        item-text="name"
                        item-value="id"
                        :color="gColor"
                        outlined
                        :disabled="loadPricingDetailsActions || hasFlatComponent"
                        :readonly="readonlyComponent"
                        dense/>
                    </v-col>
                    <v-col cols="1">
                      <v-autocomplete
                        v-model="contractPricingDetail.currency_id"
                        label="Moeda*"
                        :items="currencies"
                        item-text="name"
                        item-value="id"
                        :color="gColor"
                        outlined
                        :disabled="loadPricingDetailsActions || hasFlatComponent"
                        :readonly="readonlyComponent"
                        dense
                        @change="changeCurrency"/>
                    </v-col>
                    <v-col cols="2">
                      <v-currency-field
                        v-model="contractPricingDetail.price"
                        label="Preço*"
                        :color="gColor"
                        :rules="[rules.nozero]"
                        validate-on-blur
                        :prefix="pricingOptions.prefix"
                        :locale="pricingOptions.locale"
                        :disabled="loadPricingDetailsActions || hasFlatComponent"
                        outlined
                        dense/>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="2">
                  <v-row dense>
                    <v-col cols="auto">
                      <v-btn
                        color="primary"
                        :loading="loadPricingDetailsActions"
                        :disabled="loadPricingDetailsActions || hasFlatComponent"
                        class="pa-0"
                        @click="saveDetails">
                        <v-icon left>
                          {{ contractPricingDetail.id ? 'mdi-autorenew' : 'mdi-plus' }}
                        </v-icon>
                        {{ contractPricingDetail.id ? 'Atualizar' : 'Adicionar' }}
                        </v-btn>
                    </v-col>
                    <v-col cols="auto" v-if="contractPricingDetail.id">
                      <v-btn
                        color="red"
                        @click="clearDetails">
                        <v-icon left>
                          mdi-close
                        </v-icon>
                        Cancelar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-row>
          <v-skeleton-loader
            v-if="quering"
            v-bind="crudSkelletonAttrs"
            type="table-heading@2, list-item-two-line"
            width="100%"/>

          <v-data-table
            v-else
            :headers="headersPricingDetails"
            :items="contractPricingDetails"
            :items-per-page="5"
            :loading="quering"
            sort-by="id"
            class="elevation-0 table-full-width"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-arrow-left',
              nextIcon: 'mdi-arrow-right'
            }">
            <template v-slot:[`header.tradeslip_pricing_component.name`]="{ header }">
              <v-tooltip top dark>
                <template v-slot:activator="{ on }">
                  <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
                </template>
                <span>{{ header.tooltip }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`header.security_ref`]="{ header }">
              <v-tooltip top dark>
                <template v-slot:activator="{ on }">
                  <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
                </template>
                <span>{{ header.tooltip }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`header.date`]="{ header }">
              <v-tooltip top dark>
                <template v-slot:activator="{ on }">
                  <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
                </template>
                <span>{{ header.tooltip }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`header.amount`]="{ header }">
              <v-tooltip top dark>
                <template v-slot:activator="{ on }">
                  <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
                </template>
                <span>{{ header.tooltip }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`header.price`]="{ header }">
              <v-tooltip top dark>
                <template v-slot:activator="{ on }">
                  <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
                </template>
                <span>{{ header.tooltip }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`header.currency.name`]="{ header }">
              <v-tooltip top dark>
                <template v-slot:activator="{ on }">
                  <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
                </template>
                <span>{{ header.tooltip }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`header.unit.name`]="{ header }">
              <v-tooltip top dark>
                <template v-slot:activator="{ on }">
                  <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
                </template>
                <span>{{ header.tooltip }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`header.actions`]="{ header }">
              <v-tooltip top dark>
                <template v-slot:activator="{ on }">
                  <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
                </template>
                <span>{{ header.tooltip }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.tradeslip_pricing_component.name`]="{ item }">
              <span class="font-weight-medium">{{ item.tradeslip_pricing_component.name }}</span>
            </template>
            <template v-slot:[`item.date`]="{ item }">
              <span>{{ item.date | formatterBRZDate }}</span>
            </template>
            <template v-slot:[`item.amount`]="{ item }">
              <span v-if="item.tradeslip_pricing_component.identifier === 'se'">{{ item.amount | formatterUnit(0) }}</span>
              <span v-else>{{ item.amount | formatterUnit(2) }} {{ contract.unit.symbol }}</span>
            </template>
            <template v-slot:[`item.price`]="{ item }">
              <span v-if="['se', 'basis'].includes(item.tradeslip_pricing_component.identifier)">¢ {{ item.price }}</span>
              <div v-else>
                <span v-if="item.currency.identifier === 'R'">{{ item.price | formatterReal }}</span>
                <span v-if="item.currency.identifier === 'D'">{{ item.price | formatterDollar }}</span>
              </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <agr-actions-buttons-table
                :item="item"
                @edit="editComponent"
                @confirmDelete="confirmDeleteComponent"/>
            </template>
            <template v-slot:no-data>
              <v-container
                fluid
                fill-height
                style="background-color: rgba(255, 255, 255, 0.5);"
              >
                <v-layout justify-center align-center>
                  <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
                  <strong
                    v-else
                    class="py-2"
                    style="font-color:red;font-size:14px"
                  >Nenhum componente encontrado para este contrato</strong>
                </v-layout>
              </v-container>
            </template>
          </v-data-table>
        </v-row>
      </div>
    </v-scroll-y-transition>
  </div>
</template>

<script>
import { tradeslip } from "@/mixins/tradeslip";
import { mapGetters, mapActions } from "vuex";
import { utilsService } from '@/services/utils';

import Business from "@/utilsBusiness";
import constants from "@/constants";
import { decimalBRZToNumber } from '@/utilsNumber';

export default {
  name: "ContractPricing",
  mixins: [tradeslip],
  props: {
    contract: Object,
    gColor: String,
  },
  data() {
    return {
      loadPricingActions: false,
      contractPricingSelected: [],
      contractPricing: undefined,
      contractPricingToFixing: undefined,
      constants: constants.get(this.contract.growing.symbol),
      pricingOptions: {
        prefix: "$",
        locale: "en",
      },
      security_ref: undefined,
      headersPricing: [
        {
          text: "Período",
          tooltip: "Período de entrega cadastrado",
          align: "left",
          value: "tradeslip_delivery_period.ref_period",
        },
        {
          text: "Quantidade",
          tooltip: "Quantidade informada para precificação",
          align: "center",
          value: "amount",
        },
        {
          text: "Dólar",
          tooltip: "Valor do dólar utilizado para o cálculo da precificação",
          align: "center",
          value: "dollar",
        },
        {
          text: "Preço",
          tooltip: "Endereço do silo",
          align: "center",
          value: "detailed_price",
        },
        {
          text: "Ações",
          align: "center",
          tooltip: "Modificar ou excluir o silo do contrato",
          value: "actions",
          sortable: false,
        },
      ],
      defaultContractPricing: {
        amount: null,
        tradeslip_delivery_period_id: undefined,
        address_complement: undefined,
        tradeslip_id: undefined,
      },
      componentValidation: new Map([
        [
          "EXW",
          [
            {
              text: "Prêmio FOB",
              value: "basis",
            },
            {
              text: "Futuros na bolsa",
              value: "se",
            },
            {
              text: "Frete",
              value: "shipping",
            },
            {
              text: "Custos portuários",
              value: "fobbings",
            },
            {
              text: "Logística (Frete + Custos portuários)",
              value: "log",
            },
          ],
        ],
        [
          "FOB",
          [
            {
              text: "Prêmio FOB",
              value: "basis",
            },
            {
              text: "Futuros na bolsa",
              value: "se",
            },
          ],
        ],
        [
          "FAS",
          [
            {
              text: "Prêmio FOB",
              value: "basis",
            },
            {
              text: "Futuros na bolsa",
              value: "se",
            },
            {
              text: "Custos portuários",
              value: "fobbings",
            },
          ],
        ],
      ]),
      flatValidation: [
        {
          text: "Flat Price",
          value: "flat",
        },
      ],

      // PRICING DETAILS
      options: {
        min: -999999,
        minEvent: "SetValueMin",
      },
      properties: {
        validateOnBlur: true,
      },
      rulesPricing: {
        maxAmountDelivery: (value) => {
          if (!this.contractPricing.tradeslip_delivery_period_id || !value)
            return true;
          let delivery = this.contractTradeslipDeliveries.find(
            (cd) => cd.id === this.contractPricing.tradeslip_delivery_period_id
          );
          return (
            decimalBRZToNumber(value) <= parseFloat(delivery.amount) ||
            "Valor superior a quantidade do período"
          );
        },
      },
      rulesDetailPricing: {
        sumMaxAmountPricing: (value) => {
          if (!this.contractPricingToFixing || !value) return true;
          return (
            decimalBRZToNumber(value) <=
              parseFloat(this.contractPricingToFixing.amount) ||
            "Valor superior ao informado no período"
          );
        },
        sumMaxAmountComponent: (value) => {
          if (!this.contract || !value) return true;
          let sumAmount = 0;
          if (
            this.componentSelected &&
            this.componentSelected.identifier === "basis"
          )
            sumAmount =
              decimalBRZToNumber(value) + this.sumComponentBasis;
          else if (
            this.componentSelected &&
            this.componentSelected.identifier === "shipping"
          )
            sumAmount =
              decimalBRZToNumber(value) + this.sumComponentShipping;
          else if (
            this.componentSelected &&
            this.componentSelected.identifier === "fobbings"
          )
            sumAmount =
              decimalBRZToNumber(value) + this.sumComponentFobbings;
          return (
            sumAmount <= parseFloat(this.contractPricingToFixing.amount) ||
            "Soma do componente é superior a quantidade do período"
          );
        },
      },
      loadPricingDetailsActions: false,
      loadPricingDetailsDelete: false,
      // mostra os tradeslip_pricing_detail do tradeslip_pricing selecionado
      contractPricingDetails: [],
      contractPricingDetail: undefined,
      defaultContractPricingDetail: {
        security_ref: undefined,
        amount: null,
        date: undefined,
        price: null,
        currency_id: undefined,
        unit_id: undefined,
        tradeslip_pricing_component_id: undefined,
        tradeslip_pricing_id: undefined,
      },
      headersPricingDetails: [
        {
          text: "Componente",
          tooltip: "Componente da precificação",
          align: "left",
          value: "tradeslip_pricing_component.name",
        },
        {
          text: "Referência",
          tooltip: "Referência do contrato em bolsa",
          align: "center",
          value: "security_ref",
        },
        {
          text: "Data de fixação",
          tooltip: "Data de criação do componente",
          align: "center",
          value: "date",
        },
        {
          text: "Quantidade",
          tooltip: "Quantidade informada para precificação",
          align: "center",
          value: "amount",
        },
        {
          text: "Preço",
          tooltip: "Preço do componente",
          align: "center",
          value: "price",
        },
        {
          text: "Moeda",
          tooltip: "Moeda do componente",
          align: "center",
          value: "currency.name",
        },
        {
          text: "Medida",
          tooltip: "Medida do componente",
          align: "center",
          value: "unit.name",
        },
        {
          text: "Ações",
          align: "center",
          tooltip: "Modificar ou excluir o silo do contrato",
          value: "actions",
          sortable: false,
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      contractTradeslipDeliveries: "getContractDeliveries",
      contractPricings: "getContractPricings",
      allContractPricingDetails: "getAllContractPricingDetails",
      contractPricingComponents: "getContractPricingComponents",
      stockContracts: "getStockContracts",
      quering: "getQuering",
    }),
    mandatoryComponentsByIncoterms() {
      return this.contract.fixed_price
        ? this.flatValidation
        : this.componentValidation.get(this.contract.incoterm.name);
    },
    pricingComponentsFiltered() {
      const validComponents = this.mandatoryComponentsByIncoterms.map(
        (el) => el.value
      );
      const mandatories = this.contractPricingComponents.filter((el) =>
        validComponents.includes(el.identifier)
      );
      if (
        this.contractPricingDetails &&
        this.contractPricingDetails.length > 0
      ) {
        const nameComponentAlreadyInserted = this.contractPricingDetails.map(
          (el) => el.tradeslip_pricing_component.identifier
        );
        if (this.contract.incoterm.name === "EXW") {
          const componentAlreadyInserted = mandatories.filter(
            (el) => !nameComponentAlreadyInserted.includes(el.identifier)
          );
          if (nameComponentAlreadyInserted.includes("log")) {
            return componentAlreadyInserted.filter(
              (el) => !["shipping", "fobbings"].includes(el.identifier)
            );
          } else if (
            nameComponentAlreadyInserted.includes("shipping") ||
            nameComponentAlreadyInserted.includes("fobbings")
          ) {
            return componentAlreadyInserted.filter(
              (el) => el.identifier !== "log"
            );
          }
          return componentAlreadyInserted;
        }
        return mandatories.filter(
          (el) => !nameComponentAlreadyInserted.includes(el.identifier)
        );
      }
      return mandatories;
    },
    priceOptions() {
      let curr = this.currencies.find(
        (c) => c.id === this.contractPricingDetail.currency_id
      );
      let result = Object.assign({}, this.currencyOptions);
      if (curr && curr.identifier === "D") result.locale = "en";
      return result;
    },
    // Retorna o componente da precificação selecionado
    componentSelected() {
      if (this.contractPricingDetail.tradeslip_pricing_component_id) {
        return this.contractPricingComponents.find(
          (cpc) =>
            cpc.id === this.contractPricingDetail.tradeslip_pricing_component_id
        );
      }
      return undefined;
    },
    // Somatoria da qtde dos componentes basis
    // para não passar a qtde do contrato
    sumComponentBasis() {
      if (
        this.contractPricingDetails &&
        this.contractPricingDetails.length > 0
      ) {
        let basis = this.contractPricingDetails.filter(
          (cp) => cp.tradeslip_pricing_component.identifier === "basis"
        );
        if (basis.length > 0) {
          if (this.contractPricingDetail.id) {
            basis.splice(
              basis.findIndex((b) => b.id === this.contractPricingDetail.id),
              1
            );
          }
          return basis.reduce(function (accumulator, currentValue) {
            return accumulator + parseFloat(currentValue.amount);
          }, 0);
        }
      }
      return 0;
    },
    // Somatoria da qtde dos componentes shipping
    // para não passar a qtde do contrato
    sumComponentShipping() {
      if (
        this.contractPricingDetails &&
        this.contractPricingDetails.length > 0
      ) {
        let shipping = this.contractPricingDetails.filter(
          (cp) => cp.tradeslip_pricing_component.identifier === "shipping"
        );
        if (shipping.length > 0) {
          if (this.contractPricingDetail.id) {
            shipping.splice(
              shipping.findIndex((b) => b.id === this.contractPricingDetail.id),
              1
            );
          }
          return shipping.reduce(function (accumulator, currentValue) {
            return accumulator + parseFloat(currentValue.amount);
          }, 0);
        }
      }
      return 0;
    },
    // Somatoria da qtde dos componentes fobbings
    // para não passar a qtde do contrato
    sumComponentFobbings() {
      if (
        this.contractPricingDetails &&
        this.contractPricingDetails.length > 0
      ) {
        let shipping = this.contractPricingDetails.filter(
          (cp) => cp.tradeslip_pricing_component.identifier === "fobbings"
        );
        if (shipping.length > 0) {
          if (this.contractPricingDetail.id) {
            shipping.splice(
              shipping.findIndex((b) => b.id === this.contractPricingDetail.id),
              1
            );
          }
          return shipping.reduce(function (accumulator, currentValue) {
            return accumulator + parseFloat(currentValue.amount);
          }, 0);
        }
      }
      return 0;
    },
    growingName() {
      return Business.growingEnglishName(this.contract.growing.symbol);
    },
    // Verifica se o componente está em edição
    isEditingComponent() {
      if (this.contractPricingDetail.id) {
        return true;
      }
      return false;
    },
    // Verifica se um componente Flat foi inserido
    // se sim, desabilita edição
    hasFlatComponent() {
      if (!this.isEditingComponent) {
        if (
          this.contractPricingDetails &&
          this.contractPricingDetails.length > 0
        ) {
          return (
            this.contractPricingDetails.findIndex(
              (cp) => cp.tradeslip_pricing_component.identifier === "flat"
            ) !== -1
          );
        }
      }
      return false;
    },
    // de acordo com o componente de precficação
    // retorna true para readonly
    readonlyComponent() {
      if (this.componentSelected) {
        if (["basis", "se"].includes(this.componentSelected.identifier))
          return true;
      }
      return false;
    },
    isPriceComplete() {
      if (this.contractPricingToFixing) {
        if (this.contractPricingDetails) {
          if (this.mandatoryComponentsByIncoterms) {
            for (let component of this.mandatoryComponentsByIncoterms) {
              if (component.value === "log") {
                continue;
              }
              if (this.contract.incoterm.name === "EXW") {
                const hasLogistic = this.contractPricingDetails.find(
                  (el) => el.tradeslip_pricing_component.identifier === "log"
                )
                  ? true
                  : false;
                if (
                  !this.contractPricingDetails.find(
                    (el) =>
                      el.tradeslip_pricing_component.identifier ===
                      component.value
                  )
                ) {
                  if (!["shipping", "fobbings"].includes(component.value)) {
                    return false;
                  } else if (!hasLogistic) {
                    return false;
                  }
                }
              } else {
                if (
                  !this.contractPricingDetails.find(
                    (el) =>
                      el.tradeslip_pricing_component.identifier ===
                      component.value
                  )
                )
                  return false;
              }
            }
            return true;
          }
        }
      }
      return false;
    },
    missingComponents() {
      if (this.contractPricingToFixing) {
        if (this.contractPricingDetails) {
          if (this.mandatoryComponentsByIncoterms) {
            let miss = [];
            // Porcorre os componentes obrigatorios
            for (let component of this.mandatoryComponentsByIncoterms) {
              // procura se o componente foi inserido
              const pricingComponent = this.contractPricingDetails.find(
                (el) =>
                  el.tradeslip_pricing_component.identifier === component.value
              );
              if (!pricingComponent) {
                // se o componente não foi inserido
                // verifica se o contrato é EXW
                // lógica mais complexa por causa da logística
                if (this.contract.incoterm.name === "EXW") {
                  // procura se tem logistica inserida
                  const hasLogistic = this.contractPricingDetails.find(
                    (el) => el.tradeslip_pricing_component.identifier === "log"
                  )
                    ? true
                    : false;
                  // se o componente é diferente de frete ou custos portuários
                  if (
                    !["shipping", "fobbings", "log"].includes(component.value)
                  ) {
                    // já retorna como faltante
                    miss.push(`<li>${component.text}</li>`);
                  } else if (!hasLogistic) {
                    if (component.value === "log") {
                      // se é logistica
                      // verifica se tem frete ou custo portuário inserido
                      const hasShipping = this.contractPricingDetails.find(
                        (el) =>
                          el.tradeslip_pricing_component.identifier ===
                          "shipping"
                      )
                        ? true
                        : false;
                      const hasFobbings = this.contractPricingDetails.find(
                        (el) =>
                          el.tradeslip_pricing_component.identifier ===
                          "fobbings"
                      )
                        ? true
                        : false;
                      if (!hasShipping && !hasFobbings) {
                        // se não tem nenhum dos dois
                        // retorna a logística como faltante
                        miss.push(`<li>${component.text}</li>`);
                      }
                    } else {
                      // se é frete ou custo portuario
                      // retorna como faltante
                      miss.push(`<li>${component.text}</li>`);
                    }
                  }
                } else {
                  miss.push(`<li>${component.text}</li>`);
                }
              }
            }
            return miss;
          }
        }
      }
      return undefined;
    },
    alertStatus() {
      if (this.contractPricingToFixing) {
        if (this.isPriceComplete) {
          return "success";
        }
        return "error";
      }
      return "info";
    },
    alertHtml() {
      if (this.contractPricingToFixing) {
        if (this.isPriceComplete) {
          return `
            <b>Fixação de preço completa</b>
          `;
        } else {
          if (this.missingComponents) {
            let list = "";
            this.missingComponents.forEach((el) => {
              list += `
                ${el}
              `;
            });
            return `
              <b>Preço incompleto, faltam os seguintes componentes:</b>
              <ul>
                ${list}
              </ul>
            `;
          } else {
            return "<b>Preço incompleto</b>";
          }
        }
      }
      const fixedPrice = this.contract.fixed_price
        ? "preço fixo"
        : "preço a fixar";
      let list = "";
      this.componentPricingDependencies().forEach((el) => {
        list += `
          ${el}
        `;
      });
      return `
        <b>Contrato ${this.contract.incoterm.name} com ${fixedPrice} deve conter os seguintes componentes de precificação:</b>
        <ul>
          ${list}
        </ul>
      `;
    },
  },

  methods: {
    ...mapActions({
      loadPricings: "PRICING_LOAD",
    }),
    updateDetails(item) {
      // Atualiza o array temp de details
      let index = this.contractPricingDetails.findIndex(
        (element) => element.id === item.id
      );
      if (index > -1) this.$set(this.contractPricingDetails, index, item);
      else this.contractPricingDetails.push(item);

      // Atualiza o array com todos os details
      index = this.allContractPricingDetails.findIndex(
        (element) => element.id === item.id
      );
      if (index > -1) this.$set(this.allContractPricingDetails, index, item);
      else this.allContractPricingDetails.push(item);
    },
    deleteFromDetails(item) {
      // Atualiza o array temp de details
      let index = this.contractPricingDetails.findIndex(
        (element) => element.id === item.id
      );
      if (index > -1) this.contractPricingDetails.splice(index, 1);

      // Atualiza o array com todos os details
      index = this.allContractPricingDetails.findIndex(
        (element) => element.id === item.id
      );
      if (index > -1) this.allContractPricingDetails.splice(index, 1);
    },
    async searchTradeslipPrincingById(id) {
      const payload = {
        resource: "tradeslip_pricing",
        params: {
          id: id,
        },
      };
      try {
        let result = await this.getResourceById(payload);
        this.updateResourceList("contractPricings", result);
        this.load = false;
      } catch (err) {
        // catches errors both in fetch and response.json
        this.$alertError("Houve um problema ao atualizar a formação de preço");
        console.error(err);
        this.load = false;
      }
    },
    save() {
      if (this.validate()) {
        this.contractPricing.tradeslip_id = this.contract.id;

        this.setResource("tradeslip_pricing");
        this.saveOrUpdate(this.contractPricing)
          .then((response) => {
            this.$alertSuccess("Inserido com sucesso no contrato");
            this.updateResourceList("contractPricings", response);
            this.clear();
            this.calcSumAmounts();
          })
          .catch(() => {
            this.$alertError("Houve um problema ao inserir no contrato");
          });
      }
    },
    initialize() {
      this.contractPricing = Object.assign({}, this.defaultContractPricing);
      this.contractPricingDetail = Object.assign(
        {},
        this.defaultContractPricingDetail
      );
    },
    edit(item) {
      this.contractPricing = Object.assign({}, item);
      this.calcSumAmounts();
    },
    confirmDelete(dcontractpricing) {
      this.loadPricingDetailsDelete = true;
      this.setResource("tradeslip_pricing");
      this.delete(dcontractpricing.id)
        .then(() => {
          this.$alertSuccess("Formação removida com sucesso!");
          this.removeFromResourceList("contractPricings", dcontractpricing);
          this.removeAllPricingDetails(dcontractpricing.id);
          this.calcSumAmounts();
          this.loadPricingDetailsDelete = false;
        })
        .catch(() => {
          this.$alertError("Houve um problema ao remover a formação");
          this.loadPricingDetailsDelete = false;
        });
    },
    // Set o item do periodo selecionado para precificar
    setToFixing(item) {
      if (!this.contractPricingToFixing) {
        this.contractPricingToFixing = Object.assign({}, item);
        this.contractPricingDetails = this.allContractPricingDetails.filter(
          (cp) => cp.tradeslip_pricing_id === this.contractPricingToFixing.id
        );
        this.contractPricingSelected = [this.contractPricingToFixing];
        this.getReferenceCode(
          this.contractPricingToFixing.tradeslip_delivery_period_id
        );
      }
    },
    // Set o item do periodo selecionado para precificar
    setToFixingCheckBox(item) {
      if (item.value) {
        this.contractPricingToFixing = Object.assign({}, item.item);
        this.contractPricingDetails = this.allContractPricingDetails.filter(
          (cp) => cp.tradeslip_pricing_id === this.contractPricingToFixing.id
        );
        this.contractPricingSelected = [this.contractPricingToFixing];
        this.getReferenceCode(
          this.contractPricingToFixing.tradeslip_delivery_period_id
        );
      } else {
        this.closeDetails();
      }
    },
    calcSumAmounts() {
      // Soma os valores dos silos já cadastrados
      // para validação pela rules
      // sumResourceAmount FROM MIXIN
      if (this.contractPricing.id) {
        // Remove o item em atualização
        // para não contar na soma
        let arr = this.contractPricings.filter(
          (cd) => cd.id !== this.contractPricing.id
        );
        if (arr && arr.length > 0)
          this.sumResourceAmount = arr.reduce(function (
            accumulator,
            currentValue
          ) {
            return accumulator + parseFloat(currentValue.amount);
          },
          0);
        else this.sumResourceAmount = 0;
      } else {
        this.sumResourceAmount = this.contractPricings.reduce(function (
          accumulator,
          currentValue
        ) {
          return accumulator + parseFloat(currentValue.amount);
        },
        0);
      }
    },
    removeAllPricingDetails(pricingId) {
      // Remove todas os tradeslip_pricing_detail do tradeslip_price deletado
      // atualizando o front
      let pricingDetailsToRemove = this.contractPricingDetails.filter(
        (cpd) => (cpd.tradeslip_pricing_id = pricingId)
      );
      if (pricingDetailsToRemove.length > 0) {
        pricingDetailsToRemove.forEach((element) => {
          this.deleteFromDetails(element);
        });
      }
    },
    async getReferenceCode(delivery_period_id) {
      // Busca o codigo para sugirir de referencia
      // baseado na data de entrega
      let delivery = this.contractTradeslipDeliveries.find(
        (element) => element.id === delivery_period_id
      );
      let params = {
        growing: this.growingName,
        date: delivery.end_date.substr(0, 10),
      };
      try {
        let result = await utilsService.getQuotationsByDate(params);
        result = result.quotes;
        if (result) {
          let security = undefined;
          // Retira as primeiras letras da cultura
          if (result.se.length === 5) {
            security = result.se.code.substring(2);
          } else {
            security = result.se.code.substring(1);
          }
          // Se o code não existe no array
          // add ele e seleciona
          if (this.stockContracts.indexOf(security) === -1) {
            this.stockContracts.push(security);
          }
          this.security_ref = security;
        }
      } catch (err) {
        console.log("Erro pegar a referência");
        console.log(err);
      }
    },

    // DETAILS
    saveDetails() {
      if (this.validateDetails()) {
        this.loadPricingDetailsActions = true;
        this.contractPricingDetail.tradeslip_pricing_id =
          this.contractPricingToFixing.id;
        this.contractPricingDetail.date = this.dateISONotNull(
          this.contractPricingDetail.date
        );
        this.setResource("tradeslip_pricing_detail");
        this.saveOrUpdate(this.contractPricingDetail)
          .then((response) => {
            this.$alertSuccess("Componente fixado com sucesso no contrato");
            this.updateDetails(response);
            this.searchTradeslipPrincingById(response.tradeslip_pricing_id);
            this.loadPricingDetailsActions = false;
            this.clearDetails();
          })
          .catch(() => {
            this.$alertError(
              "Houve um problema ao fixar o componente no contrato"
            );
            this.loadPricingDetailsActions = false;
          });
      }
    },
    resetDetails() {
      this.$refs.detailsForm.resetValidation();
    },
    validateDetails() {
      return this.$refs.detailsForm.validate();
    },
    editComponent(item) {
      this.contractPricingDetail = Object.assign({}, item);
      this.configComponent();
    },
    // Faz voltar os campos da formação de preço para o estado inicial
    clearDetails() {
      this.initialize();
      this.resetDetails();
    },
    confirmDeleteComponent(dcontractpricingdetail) {
      this.setResource("tradeslip_pricing_detail");
      this.delete(dcontractpricingdetail.id)
        .then(() => {
          this.$alertSuccess("Fixação de preço removida com sucesso!");
          this.deleteFromDetails(dcontractpricingdetail);
          this.searchTradeslipPrincingById(
            dcontractpricingdetail.tradeslip_pricing_id
          );
        })
        .catch(() => {
          this.$alertError("Houve um problema ao remover a fixação de preço");
        });
    },
    // Confgura os campos da precificação
    // conforme o componente selecionado
    configComponent() {
      const component = this.contractPricingComponents.find(
        (cpc) =>
          cpc.id === this.contractPricingDetail.tradeslip_pricing_component_id
      );
      if (component) {
        switch (component.identifier) {
          case "se":
            this.contractPricingDetail.currency_id = this.currencies.find(
              (c) => c.identifier === "D"
            ).id;
            this.contractPricingDetail.unit_id = this.units.find(
              (c) => c.symbol === "bu"
            ).id;
            this.pricingOptions.prefix = "¢";
            this.pricingOptions.locale = "en";
            this.contractPricingDetail.security_ref = this.security_ref;
            this.contractPricingDetail.amount =
              this.calculeteStockExchangeContracts(
                parseFloat(this.contractPricingToFixing.amount)
              );
            break;
          case "basis":
            this.contractPricingDetail.currency_id = this.currencies.find(
              (c) => c.identifier === "D"
            ).id;
            this.contractPricingDetail.unit_id = this.units.find(
              (c) => c.symbol === "bu"
            ).id;
            this.pricingOptions.prefix = "¢";
            this.pricingOptions.locale = "en";
            this.contractPricingDetail.security_ref = this.security_ref;
            this.contractPricingDetail.amount =
              this.contractPricingToFixing.amount;
            break;
          case "shipping":
            this.pricingOptions.prefix = "R$";
            this.pricingOptions.locale = "pt-BR";
            this.contractPricingDetail.currency_id = this.currencies.find(
              (c) => c.identifier === "R"
            ).id;
            this.contractPricingDetail.unit_id = this.units.find(
              (c) => c.symbol === "t"
            ).id;
            this.contractPricingDetail.amount =
              this.contractPricingToFixing.amount;
            this.contractPricingDetail.security_ref = undefined;
            break;
          case "fobbings":
            this.pricingOptions.prefix = "$";
            this.pricingOptions.locale = "en";
            this.contractPricingDetail.currency_id = this.currencies.find(
              (c) => c.identifier === "D"
            ).id;
            this.contractPricingDetail.unit_id = this.units.find(
              (c) => c.symbol === "t"
            ).id;
            this.contractPricingDetail.amount =
              this.contractPricingToFixing.amount;
            this.contractPricingDetail.security_ref = undefined;
            break;
          case "log":
            this.pricingOptions.prefix = "R$";
            this.pricingOptions.locale = "pt-BR";
            this.contractPricingDetail.currency_id = this.currencies.find(
              (c) => c.identifier === "R"
            ).id;
            this.contractPricingDetail.unit_id = this.units.find(
              (c) => c.symbol === "t"
            ).id;
            this.contractPricingDetail.amount =
              this.contractPricingToFixing.amount;
            this.contractPricingDetail.security_ref = undefined;
            break;
          case "flat":
            this.pricingOptions.prefix = this.contract.currency.symbol;
            this.pricingOptions.locale =
              this.contract.currency.identifier === "D" ? "en" : "pt-BR";
            this.contractPricingDetail.currency_id = this.contract.currency.id;
            this.contractPricingDetail.unit_id = this.contract.unit.id;
            this.contractPricingDetail.amount =
              this.contractPricingToFixing.amount;
            this.contractPricingDetail.security_ref = this.security_ref;
            break;

          default:
            this.pricingOptions.prefix = "R$";
            this.pricingOptions.locale = "pt-BR";
            this.contractPricingDetail.currency_id = this.currencies.find(
              (c) => c.identifier === "R"
            ).id;
            this.contractPricingDetail.unit_id = this.units.find(
              (c) => c.symbol === "t"
            ).id;
            this.contractPricingDetail.amount =
              this.contractPricingToFixing.amount;
            this.contractPricingDetail.security_ref = undefined;
            break;
        }
      }
    },
    // Fecha o detalhamento da precificação
    closeDetails() {
      this.contractPricingToFixing = undefined;
      this.contractPricingSelected = [];
    },
    manualInputDateDetails(date, prop) {
      this.contractPricingDetail[prop] = date;
    },
    changeCurrency() {
      let curr = this.currencies.find(
        (c) => c.id === this.contractPricingDetail.currency_id
      );
      if (curr.identifier === "D") {
        this.pricingOptions.prefix = "$";
        this.pricingOptions.locale = "en";
      } else {
        this.pricingOptions.prefix = "R$";
        this.pricingOptions.locale = "pt-BR";
      }
    },
    componentPricingDependencies() {
      if (this.mandatoryComponentsByIncoterms) {
        return this.mandatoryComponentsByIncoterms.map(
          (el) => `<li>${el.text}</li>`
        );
      }
      return ["<li>Incoterm não identificado</li>"];
    },
    calculeteStockExchangeContracts(amount) {
      switch (this.contract.unit.symbol) {
        case "t":
          return parseInt((amount * this.constants.t) / 5000);
        case "kg":
          return parseInt(((amount / 60) * this.constants.x) / 5000);
        case "sc":
          return parseInt((amount * this.constants.x) / 5000);
        case "bu":
          return parseInt(amount / 5000);

        default:
          return undefined;
      }
    },
  },
  created() {
    this.initialize();
    this.loadPricings(this.contract.id);
    if (!this.quering) {
      this.calcSumAmounts();
    }
  },
};
</script>

<style>
</style>