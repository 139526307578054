
import Vue from "vue";
import ResultTable from "./ResultTable.vue";
import { mapGetters, mapActions } from "vuex";
import TradeslipExchangeNdf from "@/models/tradeslipExchangeNdf";
import TradeslipExchangeDollarFutures from "@/models/tradeslipExchangeDollarFutures";
import TradeslipExchangeAcc from "@/models/tradeslipExchangeAcc";

export default Vue.extend({
  name: "ContractResults",
  props: {
    contract: Object,
    gColor: String,
  },
  components: {
    ResultTable,
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapGetters({
      resultInfo: "getContractResult",
    }),
    accMaped(): TradeslipExchangeAcc[] {
      return (
        this.resultInfo?.currency_hedges?.acc?.map(
          (el: TradeslipExchangeNdf) => {
            return {
              ...el,
              result: el.ptax
                ? parseFloat((el.fee - el.ptax).toFixed(4))
                : undefined,
            };
          }
        ) || []
      );
    },
    ndfMaped(): TradeslipExchangeNdf[] {
      return (
        this.resultInfo?.currency_hedges?.ndf?.map(
          (el: TradeslipExchangeNdf) => {
            return {
              ...el,
              result: el.ptax
                ? parseFloat((el.fee - el.ptax).toFixed(4))
                : undefined,
            };
          }
        ) || []
      );
    },
    dollarFuturesMaped(): TradeslipExchangeDollarFutures[] {
      return (
        this.resultInfo?.currency_hedges?.dollar_future?.map(
          (el: TradeslipExchangeDollarFutures) => {
            const title = this.$options.filters?.formatterBRZDate(el.date);
            return {
              ...el,
              title: `${el.stock_exchange} - ${title}`,
              result: el.settled_price
                ? parseFloat((el.price - el.settled_price).toFixed(4))
                : undefined,
            };
          }
        ) || []
      );
    },
    ndfResultDetails() {
      return this.resultInfo?.currency_hedges?.ndf_details || [];
    },
  },
  methods: {
    ...mapActions({
      loadResult: "LOAD_CONTRACT_RESULT",
    }),
  },
  async mounted() {
    this.loading = true;
    await this.loadResult(this.contract.id);
    this.loading = false;
  },
});
