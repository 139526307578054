<template>
  <div>
    <v-row>
      <v-col class="text-center">
        <span class="text-h3 primary--text font-weight-medium">Futuros de Dólar</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-form ref="mainForm">
          <v-row align="start">
            <v-col cols="auto">
              <v-autocomplete
                v-model="dollarFuture.stock_exchange"
                label="Instituição*"
                :items="stockExchanges"
                :color="gColor"
                outlined
                dense
                readonly/>
            </v-col>
            <v-col cols="auto">
              <agr-date-picker
                v-model="dollarFuture.date"
                label="Contratação*"
                :color="gColor"
                outlined
                dense
                v-on:change="manualInputDate($event, 'date')"/>
            </v-col>
            <v-col cols="1">
              <v-autocomplete
                v-model="dollarFuture.ref"
                label="Referência*"
                :items="stockContracts"
                :color="gColor"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
                required/>
            </v-col>
            <v-col cols="2">
              <v-row>
                <v-col cols="8">
                  <v-text-field
                    v-model="dollarFuture.amount"
                    :color="gColor"
                    label="Contratos*"
                    outlined
                    dense
                    type="number"/>
                </v-col>
                <v-col cols="4">
                  <v-checkbox
                    v-model="dollarFuture.mini"
                    class="pt-0 mt-2"
                    label="Mini"
                    indeterminate/>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="1">
              <v-autocomplete
                v-model="dollarFuture.operation"
                label="Operação*"
                :items="trade_operation"
                item-text="name"
                item-value="value"
                :color="gColor"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
                required/>
            </v-col>
            <v-col cols="auto">
              <v-currency-field
                :value="notional"
                label="Montante*"
                :color="gColor"
                :prefix="dollarConfig.prefix"
                :locale="dollarConfig.locale"
                outlined
                dense
                readonly/>
            </v-col>
            <v-col cols="1">
              <v-autocomplete
                v-model="dollarFuture.currency_id"
                label="Moeda*"
                :items="currencies"
                item-text="name"
                item-value="id"
                :color="gColor"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
                readonly/>
            </v-col>
            <v-col cols="1">
              <v-currency-field
                v-model="dollarFuture.price"
                label="Preço*"
                :color="gColor"
                :rules="[rules.required]"
                :prefix="realConfig.prefix"
                :locale="realConfig.locale"
                :decimalLength="4"
                outlined
                dense/>
            </v-col>
            <v-col cols="1">
              <v-autocomplete
                v-model="dollarFuture.brokerage_account_id"
                label="Conta"
                :items="brokerageAccount"
                item-text="nickname"
                item-value="id"
                :color="gColor"
                validate-on-blur
                outlined
                dense
                clearable/>
            </v-col>
            <v-col cols="auto">
              <v-currency-field
                :value="notionalConverted"
                label="Montante*"
                :color="gColor"
                :prefix="realConfig.prefix"
                :locale="realConfig.locale"
                outlined
                dense
                readonly/>
            </v-col>
            <v-col cols="auto">
              <v-btn
                color="primary"
                @click="save">
                <v-icon left>
                  {{ dollarFuture.id ? 'mdi-autorenew' : 'mdi-plus' }}
                </v-icon>
                {{ dollarFuture.id ? 'Atualizar' : 'Adicionar' }}
              </v-btn>
            </v-col>
            <v-col cols="auto" v-if="dollarFuture.id">
              <v-btn
                color="red"
                @click="clear">
                <v-icon left>
                  mdi-close
                </v-icon>
                Cancelar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-data-table
        :headers="headers"
        :items="dollarFutures"
        :items-per-page="10"
        :loading="quering"
        sort-by="id"
        class="elevation-0 table-full-width"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-arrow-left',
          nextIcon: 'mdi-arrow-right'
        }">
        <template v-slot:[`header.stock_exchange`]="{ header }">
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
            </template>
            <span>{{ header.tooltip }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`header.date`]="{ header }">
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
            </template>
            <span>{{ header.tooltip }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`header.ref`]="{ header }">
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
            </template>
            <span>{{ header.tooltip }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`header.operation`]="{ header }">
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
            </template>
            <span>{{ header.tooltip }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`header.notional`]="{ header }">
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
            </template>
            <span>{{ header.tooltip }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`header.price`]="{ header }">
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
            </template>
            <span>{{ header.tooltip }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`header.notionalReal`]="{ header }">
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
            </template>
            <span>{{ header.tooltip }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`header.mini`]="{ header }">
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
            </template>
            <span>{{ header.tooltip }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`header.profitLoss`]="{ header }">
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
            </template>
            <span>{{ header.tooltip }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`header.actions`]="{ header }">
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
            </template>
            <span>{{ header.tooltip }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.stock_exchange`]="{ item }">
          <span class="font-weight-medium">{{ item.stock_exchange }}</span>
        </template>
        <template v-slot:[`item.date`]="{ item }">
          <span>{{ item.date | formatterBRZDate }}</span>
        </template>
        <template v-slot:[`item.operation`]="{ item }">
          <span>{{ nameEnumTradeOperation(item.operation) }}</span>
        </template>
        <template v-slot:[`item.notional`]="{ item }">
          <span>{{ notionalCalc(item) | formatterDollar }}</span>
        </template>
        <template v-slot:[`item.notionalReal`]="{ item }">
          <span>{{ notionalCalcReal(item) | formatterReal }}</span>
        </template>
        <template v-slot:[`item.mini`]="{ item }">
          <v-row align="center" justify="center">
          <v-checkbox
            v-model="item.mini"
            class="shrink mt-0 mr-0"
            readonly
            hide-details/>
          </v-row>
        </template>
        <template v-slot:[`item.profitLoss`]="{ item }">
          <div v-if="item.profitLoss">
            <span v-if="isDollarContract(item.currency_id)">{{ item.profitLoss | formatterReal }}</span>
            <span v-else>{{ item.profitLoss | formatterDollar }}</span>
          </div>
          <div v-else><span>-</span></div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <agr-actions-buttons-table
            :item="item"
            settled
            @edit="edit"
            @confirmDelete="confirmDelete"
            @toSettle="openSettled"/>
        </template>
        <template v-slot:no-data>
          <v-container
            fluid
            fill-height
            style="background-color: rgba(255, 255, 255, 0.5);"
          >
            <v-layout justify-center align-center>
              <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
              <strong
                v-else
                class="py-2"
                style="font-color:red;font-size:14px"
              >Nenhum futuro de dólar encontrado para este contrato</strong>
            </v-layout>
          </v-container>
        </template>
      </v-data-table>
    </v-row>
    <v-dialog
      v-model="dialog"
      mix-height="100vh"
      max-width="40vw"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      @keydown.esc="closeSettled">
      <v-card pt-3>
        <v-card-title class="form-header headline pa-2 font-weight-medium">Fututos Dólar - Liquidação</v-card-title>
        <v-card-text>
          <v-row v-if="dollarFutureSettled.isSettled">
            <v-col cols="12" align="center">
              <v-row justify=center>
                <v-col cols="auto">
                  <v-alert
                    border="left"
                    colored-border
                    type="success"
                    elevation="1"
                    class="py-1 px-2">
                    <span class="text-h4 font-weight-light">
                      Fututos já liquidados
                    </span>
                  </v-alert>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-form ref="mainForm">
            <v-row>
              <v-col cols="4">
                <agr-date-picker
                  v-model="dollarFutureSettled.settled_date"
                  label="Liquidação"
                  :color="gColor"
                  outlined
                  dense
                  v-on:change="manualInputDateSettled($event, 'settled_date')"/>
              </v-col>
              <v-col cols="4">
                <v-currency-field
                  v-model="dollarFutureSettled.settled_price"
                  label="Preço"
                  :color="gColor"
                  :prefix="realConfig.prefix"
                  :locale="realConfig.locale"
                  :decimalLength="4"
                  outlined
                  dense/>
              </v-col>
              <v-col cols="4">
                <v-currency-field
                  :value="profitAndLoss"
                  label="Resultado"
                  :color="gColor"
                  :prefix="realConfig.prefix"
                  :locale="realConfig.locale"
                  outlined
                  dense
                  readonly/>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
        <v-row justify="end" no-gutters>
          <v-col class="text-right firefox-align-end">
            <v-btn
              color="accent"
              @click="closeSettled">
              Cancelar
            </v-btn>
            <v-btn
              v-if="dollarFutureSettled.isSettled"
              color="red"
              @click="unsettled">
              Desfazer Liquidação
            </v-btn>
            <v-btn
              color="primary"
              @click="settled"
            >
              {{ dollarFutureSettled.isSettled ? 'Alterar Liquidação' : 'Liquidar' }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { tradeslip } from "@/mixins/tradeslip";
import { mapGetters, mapActions } from "vuex";
import { decimalBRZToNumber } from '@/utilsNumber';

export default {
  name: "ContractSilo",
  mixins: [tradeslip],
  props: {
    contract: Object,
    gColor: String,
  },
  data() {
    return {
      dollarFuture: undefined,
      dollarFutureSettled: undefined,
      dialog: false,
      stockExchanges: ["B3"],
      rulesDelivery: {
        maxAmountDelivery: (value) => {
          if (!this.contractDelivery || !value) return true;
          return (
            decimalBRZToNumber(value) <=
              parseFloat(this.contractDelivery.amount) ||
            "Valor superior a quantidade"
          );
        },
      },
      headers: [
        {
          text: "Instituição",
          tooltip: "Bolsa de valor",
          align: "left",
          value: "stock_exchange",
        },
        {
          text: "Contratação",
          tooltip: "Data da contratação",
          align: "left",
          value: "date",
        },
        {
          text: "Referência",
          tooltip: "Referência do contrato na bolsa",
          align: "center",
          value: "ref",
        },
        {
          text: "Operação",
          tooltip: "Contrato de venda ou compra",
          align: "center",
          value: "operation",
        },
        {
          text: "Montante",
          tooltip: "Valor contratado",
          align: "center",
          value: "notional",
        },
        {
          text: "Preço",
          tooltip: "Taxa acordada",
          align: "center",
          value: "price",
        },
        {
          text: "Montante",
          tooltip: "Valor contratado convertido",
          align: "center",
          value: "notionalReal",
        },
        {
          text: "Mini",
          tooltip: "Mini dolár",
          align: "center",
          value: "mini",
        },
        {
          text: "Resultado",
          tooltip: "P&L (Profit&Loss)",
          align: "center",
          value: "profitLoss",
        },
        {
          text: "Ações",
          align: "center",
          tooltip: "Modificar ou excluir os futuros de dólar",
          value: "actions",
          sortable: false,
        },
      ],
      defaultDollarFuture: {
        stock_exchange: "B3",
        operation: "PURCHASE",
        date: undefined,
        ref: undefined,
        amount: undefined,
        mini: false,
        price: undefined,
        isSettled: false,
        settled_date: undefined,
        settled_price: undefined,
        profitLoss: undefined,
        currency_id: undefined,
        brokerage_account_id: undefined,
        tradeslip_id: undefined,
      },
      dollarConfig: {
        prefix: "$",
        locale: "en-US",
      },
      realConfig: {
        prefix: "R$",
        locale: "pt-BR",
      },
    };
  },
  computed: {
    ...mapGetters({
      dollarFutures: "getDollarFutures",
      stockContracts: "getStockContracts",
      brokerageAccount: "getBrokerageAccount",
      quering: "getQuering",
    }),
    currencySelected() {
      if (this.dollarFuture.currency_id) {
        return this.currencies.find(
          (c) => c.id === this.dollarFuture.currency_id
        );
      }
      return undefined;
    },
    notional() {
      if (this.dollarFuture.amount) {
        let amount = parseInt(this.dollarFuture.amount);
        if (this.dollarFuture.mini) return 10000 * amount;
        else return 50000 * amount;
      }
      return 0;
    },
    notionalConverted() {
      if (this.notional && this.dollarFuture.price) {
        let price = parseFloat(this.dollarFuture.price);
        return this.notional * price;
      }
      return 0;
    },
    // Settled props #####
    profitAndLoss() {
      if (this.dollarFutureSettled.settled_price) {
        let dollar = 0;
        let numberContract = this.dollarFutureSettled.mini ? 10000 : 50000;
        if (this.dollarFutureSettled.operation === "SALE")
          dollar =
            parseFloat(this.dollarFutureSettled.price) -
            parseFloat(this.dollarFutureSettled.settled_price);
        else
          dollar =
            parseFloat(this.dollarFutureSettled.settled_price) -
            parseFloat(this.dollarFutureSettled.price);

        return (
          dollar *
          parseInt(this.dollarFutureSettled.amount) *
          numberContract
        ).toFixed(2);
      }
      return 0;
    },
    dollarId() {
      return this.currencies.find((c) => c.identifier === "D").id;
    },
  },
  methods: {
    ...mapActions({}),
    save() {
      if (this.validate()) {
        this.dollarFuture.tradeslip_id = this.contract.id;
        // Verifica se uma data foi selecionada
        // Senão utiliza a padrao
        this.dollarFuture.date = this.dateISONotNull(this.dollarFuture.date);

        this.setResource("tradeslip_exchange_dollar_futures");
        this.saveOrUpdate(this.dollarFuture)
          .then((response) => {
            this.$alertSuccess(
              "Futuros de dólar adicionado com sucesso no contrato"
            );
            this.updateResourceList("dollarFutures", response);
            this.clear();
          })
          .catch(() => {
            this.$alertError(
              "Houve um problema para adicionar os futuros de dólar"
            );
          });
      }
    },
    initialize() {
      this.dollarFuture = Object.assign({}, this.defaultDollarFuture);
      this.dollarFuture.currency_id = this.dollarId;
      this.dollarFutureSettled = Object.assign({}, this.defaultDollarFuture);
    },
    edit(item) {
      this.dollarFuture = Object.assign({}, item);
    },
    confirmDelete(dndf) {
      this.setResource("tradeslip_exchange_dollar_futures");
      this.delete(dndf.id)
        .then(async () => {
          this.$alertSuccess("NDF removida com sucesso!");
          // Faz o busca pq pode ter atualzação de ref
          this.removeFromResourceList("dollarFutures", dndf);
        })
        .catch(() => {
          this.$alertError("Houve um problema ao remover a NDF");
        });
    },
    manualInputDate(date, prop) {
      this.dollarFuture[prop] = date;
    },
    notionalCalc(item) {
      return item.mini ? 10000 * item.amount : 50000 * item.amount;
    },
    notionalCalcReal(item) {
      return item.mini
        ? 10000 * item.amount * item.price
        : 50000 * item.amount * item.price;
    },
    // Settled
    openSettled(item) {
      this.dialog = true;
      this.dollarFutureSettled = Object.assign({}, item);
      if (!this.dollarFutureSettled.isSettled)
        this.dollarFutureSettled.settled_date = this.$luxon(
          new Date().toISOString().substr(0, 10)
        ).toString();
    },
    closeSettled() {
      this.dialog = false;
      this.dollarFutureSettled = Object.assign({}, this.defaultDollarFuture);
    },
    isDollarContract(currency_id) {
      return this.currencies.find((c) => c.id === currency_id).identifier ===
        "D"
        ? true
        : false;
    },
    settled() {
      this.dollarFutureSettled.settled_date = this.$luxon(
        this.dollarFutureSettled.settled_date
      ).toString();
      this.dollarFutureSettled.isSettled = true;
      this.dollarFutureSettled.profitLoss = this.profitAndLoss;

      this.setResource("tradeslip_exchange_dollar_futures");
      this.saveOrUpdate(this.dollarFutureSettled)
        .then((response) => {
          this.$alertSuccess("NDF liquidada");
          this.updateResourceList("dollarFutures", response);
          this.initialize();
          this.reset();
          this.closeSettled();
        })
        .catch(() => {
          this.$alertError("Houve um problema ao liquidar a NDF");
        });
    },
    manualInputDateSettled(date, prop) {
      this.dollarFutureSettled[prop] = date;
    },
    unsettled() {
      this.dollarFutureSettled.isSettled = false;
      this.dollarFutureSettled.settled_date = null;
      this.dollarFutureSettled.settled_price = null;
      this.dollarFutureSettled.profitLoss = null;

      this.setResource("tradeslip_exchange_dollar_futures");
      this.saveOrUpdate(this.dollarFutureSettled)
        .then((response) => {
          this.$alertSuccess("Liquidação desfeita!");
          this.updateResourceList("dollarFutures", response);
          this.reset();
          this.closeSettled();
        })
        .catch(() => {
          this.$alertError("Houve um problema ao desfazer a liquidação");
        });
    },
  },

  created() {
    this.initialize();
  },
};
</script>

<style>
</style>