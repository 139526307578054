<template>
  <div>
    <v-row>
      <v-col>
        <v-form ref="mainForm">
          <v-row>
            <v-col cols="auto">
              <v-menu
                ref="menu"
                v-model="dialog"
                :close-on-content-click="false"
                :return-value.sync="dates"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateRangeText"
                    prepend-icon="event"
                    label="Data Início ~ Data Fim*"
                    flat
                    outlined
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dates"
                  scrollable
                  no-title
                  prev-icon="mdi-skip-previous"
                  next-icon="mdi-skip-next"
                  range
                  locale="pt-BR"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="dialog = false">
                    Cancelar
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(dates)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <!-- <agr-date-picker
                v-model="contractDelivery.start_date"
                label="Início*"
                :color="gColor"
                outlined
                range
                dense
                v-on:change="manualInputDate($event, 'start_date')"/> -->
            </v-col>
            <!-- <v-col cols="auto">
              <agr-date-picker
                v-model="contractDelivery.end_date"
                label="Fim*"
                :color="gColor"
                outlined
                dense
                v-on:change="manualInputDate($event, 'end_date')"
              />
            </v-col> -->
            <v-col cols="auto">
              <v-currency-field
                v-model="contractDelivery.amount"
                label="Quantidade*"
                :suffix="contract.unit.symbol"
                :color="gColor"
                :rules="[
                  rules.required,
                  rules.nozero,
                  rules.maxAmount,
                  rules.sumResourceMaxAmount,
                ]"
                validate-on-blur
                outlined
                dense/>
            </v-col>
            <v-col cols="auto">
              <v-currency-field
                v-model="contractDelivery.daily_cadence"
                label="Cadência diária*"
                :suffix="contract.unit.symbol"
                :color="gColor"
                :decimalLength="2"
                :rules="[
                  rules.maxAmount,
                  rulesDelivery.maxAmountDelivery,
                ]"
                validate-on-blur
                outlined
                dense/>
            </v-col>
            <v-col cols="auto">
              <v-btn color="primary" @click="save">
                <v-icon left>
                  {{ contractDelivery.id ? 'mdi-autorenew' : 'mdi-plus' }}
                </v-icon>
                {{ contractDelivery.id ? 'Atualizar' : 'Adicionar' }}
              </v-btn>
            </v-col>
            <v-col cols="auto" v-if="contractDelivery.id">
              <v-btn
                color="red"
                @click="clear">
                <v-icon left>
                  mdi-close
                </v-icon>
                Cancelar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-skeleton-loader
        v-if="quering"
        v-bind="crudSkelletonAttrs"
        type="table-heading@2, list-item-two-line"
        width="100%"
      />

      <v-data-table
        v-else
        :headers="headers"
        :items="contractDeliveries"
        :items-per-page="10"
        :loading="quering"
        sort-by="id"
        class="elevation-0 table-full-width"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-arrow-left',
          nextIcon: 'mdi-arrow-right',
        }"
      >
        <template v-slot:[`header.ref`]="{ header }">
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <span v-on="on" :class="classesTableHeader">{{
                header.text
              }}</span>
            </template>
            <span>{{ header.tooltip }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`header.ref_period`]="{ header }">
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <span v-on="on" :class="classesTableHeader">{{
                header.text
              }}</span>
            </template>
            <span>{{ header.tooltip }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`header.start_date`]="{ header }">
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <span v-on="on" :class="classesTableHeader">{{
                header.text
              }}</span>
            </template>
            <span>{{ header.tooltip }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`header.end_date`]="{ header }">
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <span v-on="on" :class="classesTableHeader">{{
                header.text
              }}</span>
            </template>
            <span>{{ header.tooltip }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`header.city`]="{ header }">
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <span v-on="on" :class="classesTableHeader">{{
                header.text
              }}</span>
            </template>
            <span>{{ header.tooltip }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`header.amount`]="{ header }">
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <span v-on="on" :class="classesTableHeader">{{
                header.text
              }}</span>
            </template>
            <span>{{ header.tooltip }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`header.daily_cadence`]="{ header }">
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <span v-on="on" :class="classesTableHeader">{{
                header.text
              }}</span>
            </template>
            <span>{{ header.tooltip }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`header.actions`]="{ header }">
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <span v-on="on" :class="classesTableHeader">{{
                header.text
              }}</span>
            </template>
            <span>{{ header.tooltip }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.ref`]="{ item }">
          <span class="font-weight-medium">{{ item.ref }}</span>
        </template>
        <template v-slot:[`item.start_date`]="{ item }">
          <span>{{ item.start_date | formatterBRZDate }}</span>
        </template>
        <template v-slot:[`item.end_date`]="{ item }">
          <span>{{ item.end_date | formatterBRZDate }}</span>
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          <span
            >{{ item.amount | formatterUnit(2) }}
            {{ contract.unit.symbol }}</span
          >
        </template>
        <template v-slot:[`item.daily_cadence`]="{ item }">
          <span
            >{{ item.daily_cadence | formatterUnit(2) }}
            {{ contract.unit.symbol }}</span
          >
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <agr-actions-buttons-table
            :item="item"
            @edit="edit"
            @confirmDelete="confirmDelete"/>
        </template>
        <template v-slot:no-data>
          <v-container
            fluid
            fill-height
            style="background-color: rgba(255, 255, 255, 0.5)"
          >
            <v-layout justify-center align-center>
              <v-progress-circular
                v-if="loading"
                indeterminate
                color="primary"
              ></v-progress-circular>
              <strong
                v-else
                class="py-2"
                style="font-color: red; font-size: 14px"
                >Nenhum período encontrado para este contrato</strong
              >
            </v-layout>
          </v-container>
        </template>
      </v-data-table>
    </v-row>
  </div>
</template>

<script>
import { tradeslip } from "@/mixins/tradeslip";
import { mapGetters, mapActions } from "vuex";

import { decimalBRZToNumber } from '@/utilsNumber';
import { formatDate } from '@/utilsDate';

export default {
  name: "ContractDelivery",
  mixins: [tradeslip],
  props: {
    contract: Object,
    gColor: String,
  },
  data() {
    return {
      contractDelivery: undefined,
      updateContractReference: false,
      rulesDelivery: {
        maxAmountDelivery: (value) => {
          if (!this.contractDelivery || !value) return true;
          return (
            decimalBRZToNumber(value) <=
              parseFloat(this.contractDelivery.amount) ||
            "Valor superior a quantidade"
          );
        },
      },
      headers: [
        {
          text: "Referência",
          tooltip: "Referência gerada pelo sistema para a entrega",
          align: "left",
          value: "ref",
        },
        {
          text: "Referência período",
          tooltip: "Referência gerada pelo sistema para o período",
          align: "left",
          value: "ref_period",
        },
        {
          text: "Início",
          tooltip: "Data do início da entrega",
          align: "center",
          value: "start_date",
        },
        {
          text: "Fim",
          tooltip: "Data do fim da entrega",
          align: "center",
          value: "end_date",
        },
        {
          text: "Quantidade",
          tooltip: "Quantidade armazenada no silo",
          align: "center",
          value: "amount",
        },
        {
          text: "Cadência diária",
          tooltip: "Quantidade entregue diariamente",
          align: "center",
          value: "daily_cadence",
        },
        {
          text: "Ações",
          align: "center",
          tooltip: "Modificar ou excluir o silo do contrato",
          value: "actions",
          sortable: false,
        },
      ],
      defaultContractDelivery: {
        ref: undefined,
        ref_period: undefined,
        start_date: "",
        end_date: "",
        amount: null,
        daily_cadence: null,
        tradeslip_id: undefined,
        tradeslip_silo_id: undefined,
      },
      dailyCadenceOptions: {
        locale: "pt-BR",
        prefix: "",
        suffix: "",
        length: 15,
        precision: 2,
      },
      dialog: false,
      dates: [],
    };
  },
  computed: {
    ...mapGetters({
      contractDeliveries: "getContractDeliveries",
      quering: "getQuering",
    }),
    dateRangeText() {
      let result = [];
      this.dates.forEach((item) => {
        result.push(this.formatFunction(item));
      });
      return result.join(" ~ ");
    },
  },
  methods: {
    ...mapActions({
      searchTradeslipDeliveries: "SEARCH_TRADESLIP_DELIVERY_PERIOD",
    }),
    formatFunction(date) {
      return formatDate(date);
    },
    nextWeek(date) {
      date.setDate(date.getDate() + 2);
      return date;
    },
    save() {
      if (this.validate()) {
        this.contractDelivery.tradeslip_id = this.contract.id;
        // Verifica se uma data foi selecionada
        // Senão utiliza a padrao
        this.contractDelivery.start_date = this.dateISONotNull(
          new Date(this.dates[0])
        );
        this.contractDelivery.end_date = this.dateISONotNull(
          new Date(this.dates[1])
        );
        this.updateContractReference = this.contractDelivery.id ? false : true;
        this.setResource("tradeslip_delivery_period");
        this.saveOrUpdate(this.contractDelivery)
          .then((response) => {
            this.$alertSuccess("Período adicionado com sucesso no contrato");
            this.updateResourceList("contractDeliveries", response);
            // Emite um evento para atualizar
            // o referencia do contrato nos dados mestres
            if (this.updateContractReference)
              this.$emit("updateReference", response.ref);
            // Faz o recalculo das quantidade
            this.calcSumAmounts();
            // Informa que houve uma atualização nos periodos
            this.clear();
          })
          .catch(() => {
            this.$alertError(
              "Houve um problema para adicionar o período no contrato"
            );
          });
      }
    },
    initialize() {
      this.contractDelivery = Object.assign({}, this.defaultContractDelivery);
      if (
        this.contractDelivery.start_date &&
        this.contractDelivery.start_date.length
      ) {
        this.dates = [
          this.contractDelivery.start_date,
          this.contractDelivery.end_date,
        ];
      } else {
        let today = new Date();
        this.dates = [
          today.toISOString().substr(0, 10),
          this.nextWeek(today).toISOString().substr(0, 10),
        ];
      }
    },
    edit(item) {
      this.contractDelivery = Object.assign({}, item);
      this.dates = [
        this.contractDelivery.start_date,
        this.contractDelivery.end_date,
      ];
      this.calcSumAmounts();
    },
    confirmDelete(dcontractdelivery) {
      this.setResource("tradeslip_delivery_period");
      this.delete(dcontractdelivery.id)
        .then(async () => {
          this.$alertSuccess("Período removido com sucesso!");
          // Faz o busca pq pode ter atualzação de ref
          await this.searchTradeslipDeliveries(this.contract.id);
          this.calcSumAmounts();
          // Informa que houve uma atualização nos periodos
          this.$emit("updatePeriod", this.contractDeliveries.length);
        })
        .catch(() => {
          this.$alertError("Houve um problema ao remover o período");
        });
    },
    manualInputDate(date, prop) {
      this.contractDelivery[prop] = date;
    },
    calcSumAmounts() {
      // Soma os valores dos silos já cadastrados
      // para validação pela rules
      // sumResourceAmount FROM MIXIN
      if (this.contractDelivery.id) {
        // Remove o item em atualização
        // para não contar na soma
        let arr = this.contractDeliveries.filter(
          (cd) => cd.id !== this.contractDelivery.id
        );
        if (arr && arr.length > 0)
          this.sumResourceAmount = arr.reduce(function (
            accumulator,
            currentValue
          ) {
            return accumulator + parseFloat(currentValue.amount);
          },
          0);
        else this.sumResourceAmount = 0;
      } else {
        this.sumResourceAmount = this.contractDeliveries.reduce(function (
          accumulator,
          currentValue
        ) {
          return accumulator + parseFloat(currentValue.amount);
        },
        0);
      }
    },
  },
  created() {
    this.initialize();
    if (!this.quering) {
      this.calcSumAmounts();
    }
  },
};
</script>

<style>
</style>