<template>
  <div>
    <div class="text-center">
      <v-overlay :value="overlay">
        <div class="pa-5 rounded-lg" style="background-color: rgb(74,77,78, 0.7);">
          <v-progress-circular
            indeterminate
            size="64"
            color="accent"
          />
          <h1>Salvando seus dados...</h1>
        </div>
      </v-overlay>
    </div>
    <v-row no-gutters>
      <v-col class="text-center">
        <span :style="'color: '+gColor"
          class="text-h3 font-weight-bold text-center"
          primary-title>
            {{ componentsText.action }} contrato
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-form ref="mainForm">
          <!-- #### REFERENCIAS #### -->
          <v-row class="mb-2">
            <v-col :style="`border: 1px solid ${gColor}`" class="rounded-lg">
              <v-row dense>
                <v-col>
                  <span :style="'color: '+gColor" class="text-h5 font-weight-medium">Referências</span>
                </v-col>
              </v-row>
              <v-row justify="space-around" no-gutters align="center">
                <v-col cols="2" :style="'color: '+gColor">
                  <span class="mr-2 text-h3 font-weight-bold">Contrato:</span>
                  <span v-if="contract.ref" class="ml-2 text-h3">{{ contract.ref }}</span>
                  <span v-else class="ml-2 text-h3">--------/--</span>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    v-model="contract.brokerage_ref"
                    label="Ref. Corretora"
                    class="pb-1"
                    outlined
                    dense
                    :color="gColor"
                    hide-details/>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    v-model="contract.buyer_ref"
                    label="Ref. Comprador"
                    class="pb-1"
                    outlined
                    dense
                    :color="gColor"
                    hide-details/>
                </v-col>
                <v-col cols="2">
                  <span class="mr-2 text-h4 font-weight-bold" :style="'color: '+gColor">Situação:</span>
                  <v-chip :color="tradeslipStatusColor(contract.status)" class="ml-2">
                    <span class="font-weight-bold white--text">{{ tradeslipStatusLabel(contract.status) }}</span>
                  </v-chip>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- #### PRODUTO #### -->
          <v-row class="my-4">
            <v-col :style="`border: 1px solid ${gColor}`" class="rounded-lg">
              <v-row dense>
                <v-col>
                  <span :style="'color: '+gColor" class="text-h5 font-weight-medium">Produto</span>
                </v-col>
              </v-row>
                <v-row justify="start" dense align="center">
                  <v-col cols="2">
                    <agr-date-picker
                      v-model="contract.deal_date"
                      label="Data do acordo*"
                      :color="gColor"
                      :rules="[this.validDealDate || 'O acordo não deve ser maior que a data atual']"
                      outlined
                      dense
                      v-on:change="manualInputDate($event, 'deal_date')"/>
                  </v-col>
                  <v-col cols="2">
                    <v-autocomplete
                      v-model="contract.operation"
                      label="Operação*"
                      :items="trade_operation"
                      item-text="name"
                      item-value="value"
                      :color="gColor"
                      :rules="[rules.required]"
                      validate-on-blur
                      outlined
                      dense
                      readonly
                      required/>
                  </v-col>
                  <v-col cols="2">
                    <v-autocomplete
                      v-model="contract.harvest_id"
                      label="Safra*"
                      :items="harvests"
                      item-text="name"
                      item-value="id"
                      :color="gColor"
                      :rules="[rules.required]"
                      validate-on-blur
                      outlined
                      dense
                      required/>
                  </v-col>
                  <v-col cols="2" class="mb-5">
                    <v-radio-group v-model="priceOption" row>
                      <v-radio
                        v-for="(p, i) in priceOptions"
                        :key="i"
                        :label="p.label"
                        :value="p.value"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="2">
                    <v-autocomplete
                      v-model="contract.incoterm_id"
                      label="Incoterm*"
                      :items="incoterms"
                      item-text="name"
                      item-value="id"
                      :color="gColor"
                      :rules="[rules.required]"
                      validate-on-blur
                      outlined
                      dense
                      required/>
                  </v-col>
                  <v-col cols="1" class="mb-5">
                    <v-checkbox
                      v-model="contract.is_export"
                      label="Exportação"/>
                  </v-col>
                </v-row>
                <v-row justify="space-around">
                  <v-col cols="2">
                    <v-autocomplete
                      v-model="contract.seller_id"
                      label="Vendedor*"
                      :items="sellers"
                      item-text="name"
                      item-value="id"
                      :color="gColor"
                      :rules="[rules.required]"
                      validate-on-blur
                      outlined
                      dense
                      required/>
                  </v-col>
                  <v-col cols="2">
                    <v-autocomplete
                      v-model="contract.buyer_id"
                      label="Comprador*"
                      :items="buyers"
                      item-text="name"
                      item-value="id"
                      :color="gColor"
                      :rules="[rules.required]"
                      validate-on-blur
                      outlined
                      dense
                      required/>
                  </v-col>
                  <v-col cols="2">
                    <v-autocomplete
                      v-model="contract.growing_id"
                      label="Produto*"
                      :items="growings"
                      item-text="name"
                      item-value="id"
                      :color="gColor"
                      :rules="[rules.required]"
                      validate-on-blur
                      outlined
                      dense
                      required/>
                  </v-col>
                  <v-col cols="2">
                    <v-currency-field
                      v-model="contract.amount"
                      label="Quantidade*"
                      :color="gColor"
                      :rules="[rules.required, rules.nozero]"
                      validate-on-blur
                      outlined
                      dense/>
                  </v-col>
                  <v-col cols="1">
                    <v-autocomplete
                      v-model="contract.unit_id"
                      label="Unidade*"
                      :items="units"
                      item-text="symbol"
                      item-value="id"
                      :color="gColor"
                      :rules="[rules.required]"
                      validate-on-blur
                      outlined
                      dense
                      required/>
                  </v-col>
                  <v-col cols="1">
                    <v-autocomplete
                      v-model="contract.currency_id"
                      label="Moeda*"
                      :items="currencies"
                      item-text="name"
                      item-value="id"
                      :color="gColor"
                      :rules="[rules.required]"
                      validate-on-blur
                      outlined
                      dense
                      required/>
                  </v-col>
                </v-row>
            </v-col>
          </v-row>
          <!-- #### CORRETAGEM #### -->
          <v-row>
            <v-col cols="7">
              <v-row>
                <v-col cols="11" :style="`border: 1px solid ${gColor}`" class="rounded-lg">
                  <v-row dense>
                    <v-col>
                      <span :style="'color: '+gColor" class="text-h5 font-weight-medium">Corretagem</span>
                    </v-col>
                  </v-row>
                  <v-row justify="space-around" dense>
                    <v-col cols="4">
                      <v-autocomplete
                        v-model="contract.brokerage_id"
                        label="Corretora"
                        :items="brokerageFirms"
                        item-text="name"
                        item-value="id"
                        :color="gColor"
                        outlined
                        dense
                        clearable/>
                    </v-col>
                    <v-col cols="2">
                      <v-currency-field
                        v-model="contract.brokerage_percent"
                        label="Percentual"
                        suffix="%"
                        :color="gColor"
                        :rules="rulesBrokeragePercentural"
                        validate-on-blur
                        outlined
                        dense/>
                    </v-col>
                    <v-col cols="2">
                      <v-currency-field
                        v-model="contract.brokerage_value"
                        label="Valor"
                        :color="gColor"
                        :rules="rulesBrokerageValueNumber"
                        validate-on-blur
                        outlined
                        dense/>
                    </v-col>
                    <v-col cols="2">
                      <v-autocomplete
                        v-model="contract.brokerage_currency_id"
                        label="Moeda"
                        :items="currencies"
                        item-text="name"
                        item-value="id"
                        :color="gColor"
                        :rules="rulesBrokerageValue"
                        outlined
                        dense
                        required/>
                    </v-col>
                    <v-col cols="2">
                      <v-autocomplete
                        v-model="contract.brokerage_unit_id"
                        label="Unidade"
                        :items="units"
                        item-text="symbol"
                        item-value="id"
                        :color="gColor"
                        :rules="rulesBrokerageValue"
                        outlined
                        dense
                        required/>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="10" class="pl-0">
                  <v-textarea
                    v-model="contract.note"
                    outlined
                    label="Observação"
                    color="primary"
                    counter="true"
                    value/>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="5">
              <!-- #### PAGAMENTO #### -->
              <v-row>
                <v-col :style="`border: 1px solid ${gColor}`" class="rounded-lg mb-5">
                  <v-row dense>
                    <v-col>
                      <span :style="'color: '+gColor" class="text-h5 font-weight-medium">Pagamento</span>
                    </v-col>
                  </v-row>
                  <v-row justify="start" dense>
                    <v-col cols="4">
                      <v-autocomplete
                        v-model="contract.payment"
                        label="Forma de pagamento"
                        :items="paymentType"
                        item-text="label"
                        item-value="value"
                        :color="gColor"
                        outlined
                        clearable
                        dense/>
                    </v-col>

                    <v-col v-if="contract.payment === 'LOAD_VOLUME'" cols="6">
                      <v-row no-gutters>
                        <v-col class="pr-2">
                          <v-currency-field
                            v-model="contract.payment_volume"
                            label="Volume"
                            :color="gColor"
                            :rules="[rules.nozero]"
                            validate-on-blur
                            outlined
                            dense/>
                        </v-col>
                        <v-col class="pl-2">
                          <v-autocomplete
                            v-model="contract.payment_weekday"
                            label="Dia da semana"
                            :items="weekDays"
                            item-text="label"
                            item-value="value"
                            :color="gColor"
                            :rules="[rules.requiredwithzero]"
                            validate-on-blur
                            outlined
                            dense
                            required/>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col v-else-if="contract.payment === 'INSTALLMENT_DATE'" cols="8">
                      <v-form ref="paymentInstallmentForm">
                        <v-row v-if="isContractSave" no-gutters>
                          <v-col class="pr-2">
                            <agr-date-picker
                              v-model="paymentInstallment.date"
                              label="Dia"
                              :color="gColor"
                              :rules="[rules.required]"
                              outlined
                              dense
                              v-on:change="manualInputDate($event, 'end_date')"/>
                          </v-col>
                          <v-col class="pl-2">
                            <v-currency-field
                              v-model="paymentInstallment.value"
                              label="Valor"
                              :color="gColor"
                              :rules="[rules.nozero]"
                              validate-on-blur
                              outlined
                              dense/>
                          </v-col>
                          <v-col cols="1" class="pl-2 pt-1">
                            <v-tooltip top dark>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  fab
                                  x-small
                                  depressed
                                  :loading="loadSavePaymentInstallment"
                                  :disabled="loadSavePaymentInstallment"
                                  :color="gColor"
                                  v-on="on"
                                  @click="savePaymentInstallment"
                                >
                                  <v-icon>mdi-content-save</v-icon>
                                </v-btn>
                              </template>
                              <span>Salvar o lançamento</span>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                        <v-row v-else>
                          <v-col><span class="red--text">Salve o contrato para poder fazer os lançamentos</span></v-col>
                        </v-row>
                      </v-form>
                    </v-col>
                    <v-col v-else-if="contract.payment === 'INSTALLMENT_AMOUNT'" cols="8">
                      <v-form ref="paymentInstallmentForm">
                        <v-row v-if="isContractSave" no-gutters>
                          <v-col class="pr-2">
                            <agr-date-picker
                              v-model="paymentInstallment.date"
                              label="Dia"
                              :color="gColor"
                              :rules="[rules.required]"
                              outlined
                              dense
                              v-on:change="manualInputDate($event, 'end_date')"/>
                          </v-col>
                          <v-col class="pl-2">
                            <v-currency-field
                              v-model="paymentInstallment.amount"
                              label="Quantidade"
                              :color="gColor"
                              :rules="[rules.nozero]"
                              validate-on-blur
                              outlined
                              dense/>
                          </v-col>
                          <v-col class="pl-2">
                            <v-currency-field
                              v-model="paymentInstallment.value"
                              label="Valor"
                              :color="gColor"
                              :rules="[rules.nozero]"
                              validate-on-blur
                              outlined
                              dense/>
                          </v-col>
                          <v-col cols="1" class="pl-2 pt-1">
                            <v-tooltip top dark>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  fab
                                  x-small
                                  depressed
                                  :loading="loadSavePaymentInstallment"
                                  :disabled="loadSavePaymentInstallment"
                                  :color="gColor"
                                  v-on="on"
                                  @click="savePaymentInstallment"
                                >
                                  <v-icon>mdi-content-save</v-icon>
                                </v-btn>
                              </template>
                              <span>Salvar o lançamento</span>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                        <v-row v-else>
                          <v-col><span class="red--text">Salve o contrato para poder fazer os lançamentos</span></v-col>
                        </v-row>
                      </v-form>
                    </v-col>

                    <v-col v-else cols="4">
                      <v-row no-gutters align="baseline">
                        <v-col cols="8"><span class="primary--text">Número de dias após:</span></v-col>
                        <v-col cols="3">
                          <v-text-field
                            v-model="contract.payment_days_after"
                            :color="gColor"
                            :rules="contract.payment ? [rules.required] : []"
                            hide-details
                            outlined
                            dense
                            required/>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row v-if="contract.payment === 'INSTALLMENT_DATE'" class="pa-1">
                    <v-data-table
                      :headers="paymentDateheader"
                      :items="paymentInstallments"
                      :items-per-page="5"
                      :loading="loadPayments"
                      sort-by="id"
                      class="elevation-0 table-full-width"
                      :footer-props="{
                        showFirstLastPage: true,
                        firstIcon: 'mdi-arrow-collapse-left',
                        lastIcon: 'mdi-arrow-collapse-right',
                        prevIcon: 'mdi-arrow-left',
                        nextIcon: 'mdi-arrow-right'
                      }">
                      <template v-slot:[`header.date`]="{ header }">
                        <v-tooltip top dark>
                          <template v-slot:activator="{ on }">
                            <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
                          </template>
                          <span>{{ header.tooltip }}</span>
                        </v-tooltip>
                      </template>
                      <template v-slot:[`header.value`]="{ header }">
                        <v-tooltip top dark>
                          <template v-slot:activator="{ on }">
                            <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
                          </template>
                          <span>{{ header.tooltip }}</span>
                        </v-tooltip>
                      </template>
                      <template v-slot:[`header.actions`]="{ header }">
                        <v-tooltip top dark>
                          <template v-slot:activator="{ on }">
                            <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
                          </template>
                          <span>{{ header.tooltip }}</span>
                        </v-tooltip>
                      </template>
                      <template v-slot:[`item.date`]="{ item }">
                        <span class="font-weight-medium">{{ item.date | formatterBRZDate }}</span>
                      </template>
                      <template v-slot:[`item.value`]="{ item }">
                        <span>{{ contract.currency.symbol }} {{ item.value | formatterUnit(2) }}</span>
                      </template>
                      <template v-slot:[`item.actions`]="{ item }">
                        <v-row no-gutters justify=center>
                          <v-col cols="auto">
                            <v-btn
                              fab
                              x-small
                              elevation="0"
                              color="accent"
                              dark
                              class="mr-1"
                              @click="editPaymentIntalment(item)">
                                <v-icon>mdi-pencil-outline</v-icon>
                            </v-btn>
                          </v-col>
                          <v-col cols="auto">
                            <agr-delete-button
                              circle
                              :item=item
                              @confirmDelete="deletePaymentIntalment"
                              class="ml-2"/>
                          </v-col>
                        </v-row>
                      </template>
                      <template v-slot:no-data>
                        <v-container
                          fluid
                          fill-height
                          style="background-color: rgba(255, 255, 255, 0.5);"
                        >
                          <v-layout justify-center align-center>
                            <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
                            <strong
                              v-else
                              class="py-2"
                              style="font-color:red;font-size:14px"
                            >Nenhum lançamento encontrado</strong>
                          </v-layout>
                        </v-container>
                      </template>
                    </v-data-table>
                  </v-row>
                  <v-row v-if="contract.payment === 'INSTALLMENT_AMOUNT'" class="pa-1">
                    <v-data-table
                      :headers="amountDateheader"
                      :items="paymentInstallments"
                      :items-per-page="5"
                      :loading="loadPayments"
                      sort-by="id"
                      class="elevation-0 table-full-width"
                      :footer-props="{
                        showFirstLastPage: true,
                        firstIcon: 'mdi-arrow-collapse-left',
                        lastIcon: 'mdi-arrow-collapse-right',
                        prevIcon: 'mdi-arrow-left',
                        nextIcon: 'mdi-arrow-right'
                      }">
                      <template v-slot:[`header.date`]="{ header }">
                        <v-tooltip top dark>
                          <template v-slot:activator="{ on }">
                            <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
                          </template>
                          <span>{{ header.tooltip }}</span>
                        </v-tooltip>
                      </template>
                      <template v-slot:[`header.value`]="{ header }">
                        <v-tooltip top dark>
                          <template v-slot:activator="{ on }">
                            <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
                          </template>
                          <span>{{ header.tooltip }}</span>
                        </v-tooltip>
                      </template>
                      <template v-slot:[`header.amount`]="{ header }">
                        <v-tooltip top dark>
                          <template v-slot:activator="{ on }">
                            <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
                          </template>
                          <span>{{ header.tooltip }}</span>
                        </v-tooltip>
                      </template>
                      <template v-slot:[`header.actions`]="{ header }">
                        <v-tooltip top dark>
                          <template v-slot:activator="{ on }">
                            <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
                          </template>
                          <span>{{ header.tooltip }}</span>
                        </v-tooltip>
                      </template>
                      <template v-slot:[`item.date`]="{ item }">
                        <span class="font-weight-medium">{{ item.date | formatterBRZDate }}</span>
                      </template>
                      <template v-slot:[`item.amount`]="{ item }">
                        <span>{{ item.amount | formatterUnit(3) }} {{ contract.unit.symbol }}</span>
                      </template>
                      <template v-slot:[`item.value`]="{ item }">
                        <span>{{ contract.currency.symbol }} {{ item.value | formatterUnit(2) }}</span>
                      </template>
                      <template v-slot:[`item.actions`]="{ item }">
                        <v-row no-gutters justify=center>
                          <v-col cols="auto">
                            <v-btn
                              fab
                              x-small
                              elevation="0"
                              color="accent"
                              dark
                              class="mr-1"
                              @click="editPaymentIntalment(item)">
                                <v-icon>mdi-pencil-outline</v-icon>
                            </v-btn>
                          </v-col>
                          <v-col cols="auto">
                            <agr-delete-button
                              circle
                              :item=item
                              @confirmDelete="deletePaymentIntalment"
                              class="ml-2"/>
                          </v-col>
                        </v-row>
                      </template>
                      <template v-slot:no-data>
                        <v-container
                          fluid
                          fill-height
                          style="background-color: rgba(255, 255, 255, 0.5);"
                        >
                          <v-layout justify-center align-center>
                            <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
                            <strong
                              v-else
                              class="py-2"
                              style="font-color:red;font-size:14px"
                            >Nenhum lançamento encontrado</strong>
                          </v-layout>
                        </v-container>
                      </template>
                    </v-data-table>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-row justify="space-between" no-gutters>
      <v-col cols=2 class="text-start">
        <v-btn
          color="primary"
          @click="save">
          <v-icon left>
            mdi-content-save
          </v-icon>
          Salvar
        </v-btn>
      </v-col>
      <v-col cols=2 class="text-end">
        <v-btn
          color="red"
          @click="cancel">
          <v-icon left>
            mdi-close
          </v-icon>
          Cancelar
        </v-btn>
      </v-col>
    </v-row>

    <v-divider class="my-5"/>

    <v-tabs
      v-model="tab"
      centered
      fixed-tabs
    >
      <v-tab
        v-for="t in tabs"
        :key="t"
        :disabled="disabledTabs"
      >
        {{ t }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" class="pt-5">
      <v-tab-item>
        <contract-silo
          :contract="contract"/>
      </v-tab-item>
      <v-tab-item>
        <contract-delivery
          :contract="contract"
          @updateReference="updateContractReference"
          @updatePeriod="updatePeriodReference"/>
      </v-tab-item>
      <v-tab-item>
        <contract-pricing
          :contract="contract"
          :contractAmount="contract.amount"/>
      </v-tab-item>
      <v-tab-item>
        <exchange
          :contract="contract"/>
      </v-tab-item>
      <v-tab-item>
        <result
          :contract="contract"
          :gColor="gColor"/>
      </v-tab-item>
    </v-tabs-items>
    <!-- Dialog dados alterados -->
    <agr-confirm-dialog
      v-model="dataChangedDialog"
      text="Existem alterações não salvas no contrato, desaja continuar?"
      @confirmed="cancel($event, true)"/>
  </div>
</template>

<script>
import { tradeslip } from "@/mixins/tradeslip";
import { mapGetters, mapActions } from "vuex"; // mapState

import ContractSilo from "./ContractSilo";
import ContractDelivery from "./ContractDelivery";
import ContractPricing from "./ContractPricing";
import Exchange from "./Exchange";
import Result from "./Result.vue";
import { iqualsTo } from '@/utilsObject';

export default {
  name: "ContractForm",
  props: {
    contractEdit: Object,
    gColor: String,
  },
  mixins: [tradeslip],
  components: {
    ContractSilo,
    ContractDelivery,
    ContractPricing,
    Exchange,
    Result,
  },

  data() {
    return {
      // obj principal do form
      contract: undefined,
      theme: undefined,
      // mostra dialog para confirmação de dados alterados
      dataChangedDialog: false,
      // load para salvar o contrato
      overlay: false,
      // model para as tabs
      tab: null,
      // Disabled nas tabs quando o contrato é novo
      disabledTabs: true,
      validDealDate: true,
      // PAYMENTS INTALLMENTS
      // obj principal do para o pagamento parcelado
      paymentInstallment: undefined,
      // lista dos pagamentos parcelados
      paymentInstallments: [],
      // load das actions do pagamento
      loadSavePaymentInstallment: false,
      // load dos items de pagamento parcelado
      loadPayments: false,
      // radio btn para o preço
      priceOption: true,
      // opções de preços
      priceOptions: [
        { label: "Preço fixo", value: true },
        { label: "Preço a fixar", value: false },
      ],
      // ENUMS -----
      tradeslipStatus: [
        { label: "Novo", value: "NEW" },
        { label: "Registrado", value: "REGISTERED" },
      ],
      // opções de tipo de pagamento
      paymentType: [
        { label: "Após a entrega", value: "AFTER_DELIVERY" },
        { label: "Após a emissão da nota", value: "AFTER_INVOICE" },
        { label: "Volume Carregado", value: "LOAD_VOLUME" },
        { label: "Fracionado (data)", value: "INSTALLMENT_DATE" },
        { label: "Fracionado (quantidade)", value: "INSTALLMENT_AMOUNT" },
      ],
      // opções para os dias da semana
      weekDays: [
        { label: "Domingo", value: 0 },
        { label: "Segunda-feira", value: 1 },
        { label: "Terça-feira", value: 2 },
        { label: "Quarta-feira", value: 3 },
        { label: "Quinta-feira", value: 4 },
        { label: "Sexta-feira", value: 5 },
        { label: "Sábado", value: 6 },
      ],
      // name das tabs
      tabs: ["Silos", "Períodos", "Precificação", "Hedge Cambial", "Resultado"],
      // DEFAULTS
      defaultContract: {
        ref: undefined,
        status: "NEW",
        deal_date: "",
        operation: "SALE",
        fixed_price: undefined,
        amount: null,
        is_export: false,
        payment: undefined,
        payment_days_after: undefined,
        payment_volume: undefined,
        payment_weekday: undefined,
        brokerage_ref: undefined,
        brokerage_id: undefined,
        brokerage_percent: null,
        brokerage_value: null,
        brokerage_currency_id: undefined,
        brokerage_unit_id: undefined,
        buyer_ref: undefined,
        buyer_id: undefined,
        note: undefined,
        seller_id: undefined,
        growing_id: undefined,
        unit_id: undefined,
        currency_id: undefined,
        harvest_id: undefined,
        incoterm_id: undefined,
        user_id: undefined,
      },
      defaultPaymentInstallment: {
        value: null,
        amount: null,
        date: undefined,
        price: undefined,
        tradeslip_id: undefined,
      },
      contractBeforeEdit: undefined,
      // HEADERS
      paymentDateheader: [
        {
          text: "Data",
          tooltip: "Data do pagamento",
          align: "left",
          value: "date",
        },
        {
          text: "Valor",
          tooltip: "Valor do pagamento",
          align: "center",
          value: "value",
        },
        {
          text: "Ações",
          align: "center",
          tooltip: "Modificar ou excluir o fornecedor/cliente",
          value: "actions",
          sortable: false,
        },
      ],
      amountDateheader: [
        {
          text: "Data",
          tooltip: "Data do pagamento",
          align: "left",
          value: "date",
        },
        {
          text: "Quantidade",
          tooltip: "Volume a ser pago",
          align: "center",
          value: "amount",
        },
        {
          text: "Valor",
          tooltip: "Valor do pagamento",
          align: "center",
          value: "value",
        },
        {
          text: "Ações",
          align: "center",
          tooltip: "Modificar ou excluir o fornecedor/cliente",
          value: "actions",
          sortable: false,
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      brokerageFirms: "getBrokerageFirms",
      incoterms: "getIncoterms",
      sellers: "getSellers",
      buyers: "getBuyers",
    }),
    componentsText() {
      if (this.contract.id) {
        return {
          action: "Atualizar",
          mSuccess: "Contrato atualizado com sucesso!",
          mError: "Houve um problema ao atualizar o contrato",
        };
      } else {
        return {
          action: "Novo",
          mSuccess: "Contrato salvo com sucesso!",
          mError: "Houve um problema ao salvar o contrato",
        };
      }
    },
    isContractSave() {
      return this.contract.status === "REGISTERED" ? true : false;
    },
    isInstallmentPayment() {
      return this.contract.payment === "INSTALLMENT_DATE" ||
        this.contract.payment === "INSTALLMENT_AMOUNT"
        ? true
        : false;
    },
    rulesBrokeragePercentural() {
      if (this.contract.brokerage_id && !this.contract.brokerage_value) {
        return [this.rules.nozero];
      }
      return [this.rules.norequired];
    },
    rulesBrokerageValueNumber() {
      if (this.contract.brokerage_id && !this.contract.brokerage_percent) {
        return [this.rules.nozero];
      }
      return [this.rules.norequired];
    },
    rulesBrokerageValue() {
      if (this.contract.brokerage_id && !this.contract.brokerage_percent) {
        return [this.rules.required];
      }
      return [this.rules.norequired];
    },
  },

  methods: {
    ...mapActions({
      posLoad: "POS_LOAD",
    }),
    // PAYMENT INSTALLMENT
    searchPaymentInstallments() {
      let payload = {
        resource: "tradeslip_payment_installment",
        params: {
          tradeslip_id: this.contract.id,
        },
      };
      this.loadPayments = true;
      this.getResource(payload).then((result) => {
        this.paymentInstallments = result;
        this.loadPayments = false;
      });
    },
    savePaymentInstallment() {
      if (this.validatePaymentInstallment()) {
        this.loadSavePaymentInstallment = true;
        this.paymentInstallment.tradeslip_id = this.contract.id;
        // Verifica se uma data foi selecionada
        // Senão utiliza a padrao
        this.paymentInstallment.date = this.dateISONotNull(
          this.paymentInstallment.date
        );

        this.setResource("tradeslip_payment_installment");
        this.saveOrUpdate(this.paymentInstallment)
          .then((response) => {
            this.$alertSuccess("Lançamento inserido com sucesso");
            this.updateLocalResourceList(this.paymentInstallments, response);
            this.loadSavePaymentInstallment = false;
            this.initializePayments();
          })
          .catch((err) => {
            this.$alertError("Houve um problema ao inserir o lançamento");
            this.loadSavePaymentInstallment = false;
            console.log(err);
          });
      }
    },
    resetPaymentInstallment() {
      this.$refs.paymentInstallmentForm.resetValidation();
    },
    validatePaymentInstallment() {
      return this.$refs.paymentInstallmentForm.validate();
    },
    editPaymentIntalment(item) {
      this.paymentInstallment = Object.assign({}, item);
    },
    deletePaymentIntalment(dpaymentInstallment) {
      this.setResource("tradeslip_payment_installment");
      this.delete(dpaymentInstallment.id)
        .then(() => {
          this.$alertSuccess("Lançamento removido com sucesso!");
          this.removeFromLocalResourceList(
            this.paymentInstallments,
            dpaymentInstallment
          );
        })
        .catch((err) => {
          this.$alertError("Houve um problema ao remover o lançamento");
          console.log(err);
        });
    },
    initializePayments() {
      if (this.isInstallmentPayment) this.resetPaymentInstallment();
      this.paymentInstallment = Object.assign(
        {},
        this.defaultPaymentInstallment
      );
    },

    // CONTRACT
    save() {
      if (this.validate()) {
        this.overlay = true;
        this.contract.is_deleted = false;
        this.contract.user_id = this.user.id;
        this.contract.fixed_price = this.priceOption;
        // Verifica se uma data foi selecionada
        // Senão utiliza a padrao
        this.contract.deal_date = this.dateISONotNull(this.contract.deal_date);
        // Remove a referencia do contrato
        // para não ser atualizada no banco
        let keepRef = this.contract.ref;
        delete this.contract.ref;

        this.setResource("tradeslip");
        this.saveOrUpdate(this.contract)
          .then((response) => {
            this.$alertSuccess(this.componentsText.mSuccess);
            // Se for update, não atualiza a ref
            if (this.contract.id) {
              this.contract = Object.assign({}, response, { ref: keepRef });
            } else {
              // Só precisa fazer o posload
              // quando salva a primeira vez
              this.contract = Object.assign({}, response);
              this.posLoad(this.contract.id);
            }
            this.setBeforeUpdateContract();
            this.overlay = false;
          })
          .catch((err) => {
            this.$alertError(this.componentsText.mError);
            console.log(err);
            this.overlay = false;
          });
      }
    },
    initialize() {
      this.priceOption = true;
      this.contract = Object.assign({}, this.defaultContract);
      this.verifyDisabledTabs();
      this.initializePayments();
    },
    getThemeColors() {
      let theme = null;
      if (this.$vuetify.theme.isDark) {
        theme = this.$vuetify.theme.themes.dark;
      } else {
        theme = this.$vuetify.theme.themes.light;
      }
      return theme;
    },
    tradeslipStatusLabel(item) {
      return this.tradeslipStatus.find((tss) => tss.value === item).label;
    },
    tradeslipStatusColor(item) {
      return item === "NEW" ? "light-green darken-1" : "amber darken-1";
    },
    // Faz verificação de alteração
    // no contrato
    hasContractChanged() {
      // check se o contrato foi salvo
      if (this.isContractSave) {
        // check se houve mudança
        if (!iqualsTo(this.contract, this.contractBeforeEdit)) {
          return true;
        }
      }
      return false;
    },
    cancel(ev, confirmed = false) {
      // Check se houve alteração no contrato
      // ou se o user já confirmou
      if (confirmed || !this.hasContractChanged()) {
        this.initialize();
        this.$emit("cancel");
      } else {
        // Se sim abre o dialog de confirmação
        this.dataChangedDialog = true;
      }
    },
    manualInputDate(date, prop) {
      this.contract[prop] = date;
    },
    verifyDate() {
      let date = new Date(`${this.contract.deal_date}T00:00:00`);
      // date without timestamp
      let today = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      );
      if (date > today) {
        // this.contract.deal_date = new Date().toISOString();
        this.validDealDate = false;
      } else {
        this.validDealDate = true;
      }
    },
    verifyDisabledTabs() {
      if (this.isContractSave) {
        this.disabledTabs = false;
        this.tab = 0;
      } else {
        this.disabledTabs = true;
      }
    },
    updateContractReference(new_ref) {
      this.contract.ref = new_ref;
    },
    updatePeriodReference(total_periods) {
      let nref = this.contract.ref.split("-");
      let year = nref[1].split("/");
      if (total_periods > 0)
        this.contract.ref = `${nref[0]}-${total_periods}/${year[1]}`;
      else this.contract.ref = `${nref[0]}/${year[1]}`;
    },
    updateLocalResourceList(array, item) {
      let index = array.findIndex((element) => element.id === item.id);
      if (index > -1) this.$set(array, index, item);
      else array.push(item);
    },
    removeFromLocalResourceList(array, item) {
      let index = array.findIndex((element) => element.id === item.id);
      if (index > -1) array.splice(index, 1);
    },
    setBeforeUpdateContract() {
      this.$nextTick(() => {
        this.contractBeforeEdit = Object.assign({}, this.contract);
      });
    },
    conditionalRequired(propName) {
      if (this.contract[propName]) {
        return this.rules.required;
      }
      return this.rules.norequired;
    },
  },
  created() {
    this.initialize();
    if (this.contractEdit) {
      this.$nextTick(() => {
        this.contract = Object.assign({}, this.contractEdit);
        this.priceOption = this.contract.fixed_price;
        this.paymentInstallment = Object.assign(
          {},
          this.defaultPaymentInstallment
        );
        if (this.isInstallmentPayment) this.searchPaymentInstallments();
        this.setBeforeUpdateContract();
      });
    }
  },
  watch: {
    "contract.deal_date": function (val) {
      if (val) this.verifyDate();
    },
    "contract.status": function () {
      this.verifyDisabledTabs();
    },
  },
};
</script>
