<template>
  <div>
    <v-row>
      <v-col class="text-center">
        <span class="text-h3 primary--text font-weight-medium">Non Deliverable Forward</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-form ref="mainForm">
          <v-row>
            <v-col cols="auto">
              <v-autocomplete
                v-model="ndf.bank_id"
                label="Banco*"
                :items="banks"
                item-text="name"
                item-value="id"
                :color="gColor"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
                required/>
            </v-col>
            <v-col cols="auto">
              <v-text-field
                v-model="ndf.contract"
                :color="gColor"
                :rules="[rules.required]"
                label="Contrato*"
                outlined
                dense
                required/>
            </v-col>
            <v-col cols="auto">
              <agr-date-picker
                v-model="ndf.contract_date"
                label="Contratação*"
                :color="gColor"
                outlined
                dense
                v-on:change="manualInputDate($event, 'contract_date')"/>
            </v-col>
            <v-col cols="auto">
              <agr-date-picker
                v-model="ndf.due_date"
                label="Vencimento*"
                :color="gColor"
                outlined
                dense
                v-on:change="manualInputDate($event, 'due_date')"/>
            </v-col>
            <v-col cols="1">
              <v-text-field
                :value="contractPeriod"
                :color="gColor"
                label="Prazo*"
                outlined
                dense
                readonly/>
            </v-col>
            <v-col cols="1">
              <v-autocomplete
                v-model="ndf.operation"
                label="Operação*"
                :items="trade_operation"
                item-text="name"
                item-value="value"
                :color="gColor"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
                required/>
            </v-col>
            <v-col cols="auto">
              <v-currency-field
                v-model="ndf.notional"
                label="Montante*"
                :color="gColor"
                :rules="[rules.required]"
                :prefix="suffixSelectedCurrency.prefix"
                :locale="suffixSelectedCurrency.locale"
                outlined
                dense/>
            </v-col>
            <v-col cols="1">
              <v-autocomplete
                v-model="ndf.currency_id"
                label="Moeda*"
                :items="currencies"
                item-text="name"
                item-value="id"
                :color="gColor"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
                required/>
            </v-col>
            <v-col cols="1">
              <v-currency-field
                v-model="ndf.fee"
                label="Taxa*"
                :color="gColor"
                :rules="[rules.required]"
                :prefix="suffixOpositeCurrency.prefix"
                :locale="suffixOpositeCurrency.locale"
                :decimalLength="4"
                outlined
                dense/>
            </v-col>
            <v-col cols="auto">
              <v-currency-field
                :value="notionalConverted"
                label="Montante*"
                :color="gColor"
                :prefix="suffixOpositeCurrency.prefix"
                :locale="suffixOpositeCurrency.locale"
                outlined
                dense
                readonly/>
            </v-col>
            <v-col cols="auto">
              <v-btn
                color="primary"
                @click="save">
                <v-icon left>
                  {{ ndf.id ? 'mdi-autorenew' : 'mdi-plus' }}
                </v-icon>
                {{ ndf.id ? 'Atualizar' : 'Adicionar' }}
              </v-btn>
            </v-col>
            <v-col cols="auto" v-if="ndf.id">
              <v-btn
                color="red"
                @click="clear">
                <v-icon left>
                  mdi-close
                </v-icon>
                Cancelar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-data-table
        :headers="headers"
        :items="ndfs"
        :items-per-page="10"
        :loading="quering"
        sort-by="id"
        class="elevation-0 table-full-width"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-arrow-left',
          nextIcon: 'mdi-arrow-right'
        }">
        <template v-slot:[`header.contract`]="{ header }">
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
            </template>
            <span>{{ header.tooltip }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`header.contract_date`]="{ header }">
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
            </template>
            <span>{{ header.tooltip }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`header.due_date`]="{ header }">
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
            </template>
            <span>{{ header.tooltip }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`header.operation`]="{ header }">
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
            </template>
            <span>{{ header.tooltip }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`header.notional`]="{ header }">
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
            </template>
            <span>{{ header.tooltip }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`header.fee`]="{ header }">
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
            </template>
            <span>{{ header.tooltip }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`header.profitLoss`]="{ header }">
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
            </template>
            <span>{{ header.tooltip }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`header.actions`]="{ header }">
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <span v-on="on" :class="classesTableHeader">{{ header.text }}</span>
            </template>
            <span>{{ header.tooltip }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.contract`]="{ item }">
          <span class="font-weight-medium">{{ item.contract }}</span>
        </template>
        <template v-slot:[`item.contract_date`]="{ item }">
          <span>{{ item.contract_date | formatterBRZDate }}</span>
        </template>
        <template v-slot:[`item.due_date`]="{ item }">
          <span v-if="item.isSettled">{{ item.settled_date | formatterBRZDate }}</span>
          <span v-else>{{ item.due_date | formatterBRZDate }}</span>
        </template>
        <template v-slot:[`item.operation`]="{ item }">
          <span>{{ nameEnumTradeOperation(item.operation) }}</span>
        </template>
        <template v-slot:[`item.notional`]="{ item }">
          <span v-if="isDollarContract(item.currency_id)">{{ item.notional | formatterDollar }}</span>
          <span v-else>{{ item.notional | formatterReal }}</span>
        </template>
        <template v-slot:[`item.fee`]="{ item }">
          <div v-if="item.isSettled">
            <span>PTAX R$ {{ item.ptax | formatterUnit(4) }}</span>
          </div>
          <div v-else>
            <span v-if="isDollarContract(item.currency_id)">R$ {{ item.fee | formatterUnit(4) }}</span>
            <span v-else>{{ item.fee | formatterDollar }}</span>
          </div>
        </template>
        <template v-slot:[`item.profitLoss`]="{ item }">
          <div v-if="item.profitLoss">
            <span v-if="isDollarContract(item.currency_id)">{{ item.profitLoss | formatterReal }}</span>
            <span v-else>{{ item.profitLoss | formatterDollar }}</span>
          </div>
          <div v-else><span>-</span></div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <agr-actions-buttons-table
            :item="item"
            settled
            @edit="edit"
            @confirmDelete="confirmDelete"
            @toSettle="openSettled"/>
        </template>
        <template v-slot:no-data>
          <v-container
            fluid
            fill-height
            style="background-color: rgba(255, 255, 255, 0.5);"
          >
            <v-layout justify-center align-center>
              <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
              <strong
                v-else
                class="py-2"
                style="font-color:red;font-size:14px"
              >Nenhuma NDF encontrada para este contrato</strong>
            </v-layout>
          </v-container>
        </template>
      </v-data-table>
    </v-row>
    <v-dialog
      v-model="dialog"
      mix-height="100vh"
      max-width="40vw"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      @keydown.esc="closeSettled">
      <v-card pt-3>
        <v-card-title class="form-header headline pa-2 font-weight-medium">NDF - Liquidação</v-card-title>
        <v-card-text>
          <v-row v-if="ndfSettled.isSettled">
            <v-col cols="12" align="center">
              <v-row justify=center>
                <v-col cols="auto">
                  <v-alert
                    border="left"
                    colored-border
                    type="success"
                    elevation="1"
                    class="py-1 px-2">
                    <span class="text-h4 font-weight-light">
                      NDF já liquidada
                    </span>
                  </v-alert>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-form ref="mainForm">
            <div v-if="settledSituation.value === 'ON_DUE'">
              <v-row>
                <v-col cols="4">
                  <agr-date-picker
                    v-model="ndfSettled.settled_date"
                    label="Liquidação"
                    :color="gColor"
                    outlined
                    dense
                    v-on:change="manualInputDateSettled($event, 'settled_date')"/>
                </v-col>
                <v-col cols="4">
                  <v-autocomplete
                    :value="settledSituation"
                    label="Situação*"
                    :items="settled_situation"
                    item-text="name"
                    item-value="value"
                    :color="gColor"
                    outlined
                    dense
                    readonly/>
                </v-col>
                <v-col cols="4">
                  <v-currency-field
                    v-model="ptax"
                    label="PTAX"
                    outlined
                    dense
                    prefix="$"
                    :decimalLength="4"
                    :loading="loadingPTAX"
                    :hint="loadingPTAX ? 'Carregando valor' : ''"
                    :persistent-hint="loadingPTAX"/>
                </v-col>
              </v-row>
              <v-row >
                <v-col cols="4">
                  <v-currency-field
                    v-model="ndfSettled.fee"
                    label="Taxa"
                    :color="gColor"
                    :prefix="settledOpositeCurrency.prefix"
                    :locale="settledOpositeCurrency.locale"
                    :decimalLength="4"
                    outlined
                    dense
                    readonly/>
                </v-col>
                <v-col cols="4">
                  <v-currency-field
                    v-model="ndfSettled.notional"
                    label="Montante"
                    :color="gColor"
                    :prefix="settledCurrency.prefix"
                    :locale="settledCurrency.locale"
                    outlined
                    dense
                    readonly/>
                </v-col>
                <v-col cols="4">
                  <v-currency-field
                    :value="profitAndLossOnDue"
                    label="Resultado"
                    :color="gColor"
                    :prefix="settledOpositeCurrency.prefix"
                    :locale="settledOpositeCurrency.locale"
                    outlined
                    dense
                    readonly/>
                </v-col>
              </v-row>
            </div>
            <div v-else>
              <v-row>
                <v-col cols="4">
                  <agr-date-picker
                    v-model="ndfSettled.settled_date"
                    label="Liquidação"
                    :color="gColor"
                    outlined
                    dense
                    v-on:change="manualInputDateSettled($event, 'settled_date')"/>
                </v-col>
                <v-col cols="4">
                  <v-autocomplete
                    :value="settledSituation"
                    label="Situação*"
                    :items="settled_situation"
                    item-text="name"
                    item-value="value"
                    :color="gColor"
                    outlined
                    dense
                    readonly/>
                </v-col>
                <v-col cols="4">
                  <v-currency-field
                    v-model="ndfSettled.settled_fee"
                    label="Taxa Liquidação"
                    outlined
                    dense
                    :decimalLength="4"/>
                </v-col>
              </v-row>
              <v-row >
                <v-col cols="4">
                  <v-currency-field
                    v-model="ndfSettled.settled_interest"
                    label="Juros Pré Liquidação"
                    outlined
                    dense
                    :decimalLength="4"/>
                </v-col>
                <v-col cols="4">
                  <v-currency-field
                    v-model="ndfSettled.fee"
                    label="Taxa"
                    :color="gColor"
                    :prefix="settledOpositeCurrency.prefix"
                    :locale="settledOpositeCurrency.locale"
                    :decimalLength="4"
                    outlined
                    dense
                    readonly/>
                </v-col>
                <v-col cols="4">
                  <v-currency-field
                    v-model="ndfSettled.notional"
                    label="Montante"
                    :color="gColor"
                    :prefix="settledCurrency.prefix"
                    :locale="settledCurrency.locale"
                    outlined
                    dense
                    readonly/>
                </v-col>
              </v-row>
              <v-row >
                <v-col cols="4">
                  <v-text-field
                    :value="settledPeriod"
                    :color="gColor"
                    label="Prazo*"
                    outlined
                    dense
                    readonly/>
                </v-col>
                <v-col cols="4">
                  <v-currency-field
                    :value="profitAndLossBeforeDue"
                    label="Resultado"
                    :color="gColor"
                    :prefix="settledOpositeCurrency.prefix"
                    :locale="settledOpositeCurrency.locale"
                    outlined
                    dense
                    readonly/>
                </v-col>
              </v-row>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
        <v-row justify="end" no-gutters>
          <v-col class="text-right firefox-align-end">
            <v-btn
              color="accent"
              @click="closeSettled">
              Cancelar
            </v-btn>
            <v-btn
              v-if="ndfSettled.isSettled"
              color="red"
              @click="unsettled">
              Desfazer Liquidação
            </v-btn>
            <v-btn
              color="primary"
              @click="settled"
            >
              {{ ndfSettled.isSettled ? 'Alterar Liquidação' : 'Liquidar' }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { tradeslip } from "@/mixins/tradeslip";
import { mapGetters, mapActions } from "vuex";
import { utilsService } from '@/services/utils';
import { decimalBRZToNumber } from '@/utilsNumber';

export default {
  name: "ContractSilo",
  mixins: [tradeslip],
  props: {
    contract: Object,
    gColor: String,
  },
  data() {
    return {
      ndf: undefined,
      ndfSettled: undefined,
      dialog: false,
      loadingPTAX: false,
      ptax: undefined,
      settled_situation: [
        {
          name: "No Vencimento",
          value: "ON_DUE",
        },
        {
          name: "Antes do vencimento",
          value: "BEFORE_DUE",
        },
      ],
      rulesDelivery: {
        maxAmountDelivery: (value) => {
          if (!this.contractDelivery || !value) return true;
          return (
            decimalBRZToNumber(value) <=
              parseFloat(this.contractDelivery.amount) ||
            "Valor superior a quantidade"
          );
        },
      },
      headers: [
        {
          text: "Contrato",
          tooltip: "Referência contrato NDF",
          align: "left",
          value: "contract",
        },
        {
          text: "Contratação",
          tooltip: "Data da contratação",
          align: "left",
          value: "contract_date",
        },
        {
          text: "Liquidação",
          tooltip: "Data de liquidação do contrato",
          align: "center",
          value: "due_date",
        },
        {
          text: "Operação",
          tooltip: "Contrato de venda ou compra",
          align: "center",
          value: "operation",
        },
        {
          text: "Montante",
          tooltip: "Valor contratado",
          align: "center",
          value: "notional",
        },
        {
          text: "Taxa",
          tooltip: "Taxa acordada",
          align: "center",
          value: "fee",
        },
        {
          text: "Resultado",
          tooltip: "P&L (Profit&Loss)",
          align: "center",
          value: "profitLoss",
        },
        {
          text: "Ações",
          align: "center",
          tooltip: "Modificar ou excluir o contrato NDF",
          value: "actions",
          sortable: false,
        },
      ],
      defaultNdf: {
        contract: undefined,
        contract_date: undefined,
        due_date: undefined,
        operation: "PURCHASE",
        notional: undefined,
        fee: undefined,
        isSettled: false,
        ptax: undefined,
        settled_date: undefined,
        settled_fee: undefined,
        settled_interest: undefined,
        settled_situation: undefined,
        currency_id: undefined,
        bank_id: undefined,
        tradeslip_id: undefined,
      },
      dollarConfig: {
        prefix: "$",
        locale: "en-US",
      },
      realConfig: {
        prefix: "R$",
        locale: "pt-BR",
      },
    };
  },
  computed: {
    ...mapGetters({
      ndfs: "getNDFs",
      banks: "getBanks",
      quering: "getQuering",
    }),
    contractPeriod() {
      if (this.ndf.contract_date && this.ndf.due_date) {
        const date = this.$luxon(this.ndf.contract_date);
        const due = this.$luxon(this.ndf.due_date);
        let diff = due.diff(date, "days").toObject();
        return diff.days;
      }
      return 0;
    },
    selectedOpositeContractCurrency() {
      let currency = this.currencies.filter(
        (c) => c.id !== this.contract.currency_id
      );
      return currency.length > 0 ? currency[0].id : undefined;
    },
    currencySelected() {
      if (this.ndf.currency_id) {
        return this.currencies.find((c) => c.id === this.ndf.currency_id);
      }
      return undefined;
    },
    notionalConverted() {
      if (this.ndf.fee && this.ndf.notional && this.currencySelected) {
        let notional = parseFloat(this.ndf.notional);
        let fee = parseFloat(this.ndf.fee);
        if (this.currencySelected.identifier === "D") {
          return notional * fee;
        } else {
          return notional / fee;
        }
      }
      return 0;
    },
    suffixSelectedCurrency() {
      if (this.currencySelected.identifier === "D") {
        return this.dollarConfig;
      }
      return this.realConfig;
    },
    suffixOpositeCurrency() {
      if (this.currencySelected.identifier === "D") {
        return this.realConfig;
      }
      return this.dollarConfig;
    },
    // Settled props #####
    settledCurrency() {
      if (this.ndfSettled && this.ndfSettled.currency_id) {
        if (
          this.currencies.find((c) => c.id === this.ndfSettled.currency_id)
            .identifier === "D"
        ) {
          return this.dollarConfig;
        }
        return this.realConfig;
      }
      return this.realConfig;
    },
    settledOpositeCurrency() {
      if (this.ndfSettled && this.ndfSettled.currency_id) {
        if (
          this.currencies.find((c) => c.id === this.ndfSettled.currency_id)
            .identifier === "D"
        ) {
          return this.realConfig;
        }
        return this.dollarConfig;
      }
      return this.dollarConfig;
    },
    settledSituation() {
      if (this.ndfSettled && this.ndfSettled.settled_date) {
        const date = this.$luxon(this.ndfSettled.settled_date);
        const due = this.$luxon(this.ndfSettled.due_date);
        if (date < due) return this.settled_situation[1];
        else return this.settled_situation[0];
      }
      return "";
    },
    settledPeriod() {
      if (this.ndfSettled && this.ndfSettled.settled_date) {
        const date = this.$luxon(this.ndfSettled.contract_date);
        const settled = this.$luxon(this.ndfSettled.settled_date);
        let diff = settled.diff(date, "days").toObject();
        return diff.days;
      }
      return 0;
    },
    profitAndLossOnDue() {
      if (this.ptax) {
        if (this.ndfSettled.operation === "SALE")
          return (
            (parseFloat(this.ndfSettled.fee) - this.ptax) *
            parseFloat(this.ndfSettled.notional)
          ).toFixed(2);
        else
          return (
            (this.ptax - parseFloat(this.ndfSettled.fee)) *
            parseFloat(this.ndfSettled.notional)
          ).toFixed(2);
      }
      return 0;
    },
    profitAndLossBeforeDue() {
      if (
        this.settledPeriod &&
        this.ndfSettled.settled_fee &&
        this.ndfSettled.settled_interest
      ) {
        let dollar = 0;
        if (this.ndfSettled.operation === "SALE")
          dollar =
            parseFloat(this.ndfSettled.fee) -
            parseFloat(this.ndfSettled.settled_fee);
        else
          dollar =
            parseFloat(this.ndfSettled.settled_fee) -
            parseFloat(this.ndfSettled.fee);

        return (
          dollar *
          (parseFloat(this.ndfSettled.notional) /
            (1 + this.ndfSettled.settled_interest) **
              (this.settledPeriod / 252))
        ).toFixed(2);
      }
      return 0;
    },
  },
  methods: {
    ...mapActions({}),
    save() {
      if (this.validate()) {
        this.ndf.tradeslip_id = this.contract.id;
        // Verifica se uma data foi selecionada
        // Senão utiliza a padrao
        this.ndf.contract_date = this.dateISONotNull(this.ndf.contract_date);
        this.ndf.due_date = this.dateISONotNull(this.ndf.due_date);

        this.setResource("tradeslip_exchange_ndf");
        this.saveOrUpdate(this.ndf)
          .then((response) => {
            this.$alertSuccess("NDF adicionada com sucesso no contrato");
            this.updateResourceList("ndfs", response);
            this.clear();
          })
          .catch(() => {
            this.$alertError("Houve um problema para adicionar a NDF");
          });
      }
    },
    initialize() {
      this.ndf = Object.assign({}, this.defaultNdf);
      this.ndfSettled = Object.assign({}, this.defaultNdf);
      this.ndf.currency_id = this.selectedOpositeContractCurrency;
    },
    edit(item) {
      this.ndf = Object.assign({}, item);
    },
    confirmDelete(dndf) {
      this.setResource("tradeslip_exchange_ndf");
      this.delete(dndf.id)
        .then(async () => {
          this.$alertSuccess("NDF removida com sucesso!");
          // Faz o busca pq pode ter atualzação de ref
          this.removeFromResourceList("ndfs", dndf);
        })
        .catch(() => {
          this.$alertError("Houve um problema ao remover a NDF");
        });
    },
    manualInputDate(date, prop) {
      this.ndf[prop] = date;
    },
    openSettled(item) {
      this.dialog = true;
      this.ndfSettled = Object.assign({}, item);
      if (!this.ndfSettled.isSettled)
        this.ndfSettled.settled_date = this.$luxon(
          new Date().toISOString().substr(0, 10)
        ).toString();
    },
    closeSettled() {
      this.dialog = false;
      this.ndfSettled = Object.assign({}, this.defaultNdf);
    },
    isDollarContract(currency_id) {
      return this.currencies.find((c) => c.id === currency_id).identifier ===
        "D"
        ? true
        : false;
    },
    settled() {
      this.ndfSettled.settled_date = this.$luxon(
        this.ndfSettled.settled_date
      ).toString();
      this.ndfSettled.isSettled = true;
      this.ndfSettled.settled_situation = this.settledSituation.value;

      if (this.ndfSettled.settled_situation === "ON_DUE") {
        this.ndfSettled.profitLoss = this.profitAndLossOnDue;
        this.ndfSettled.ptax = this.ptax;
      } else {
        this.ndfSettled.profitLoss = this.profitAndLossBeforeDue;
      }

      this.setResource("tradeslip_exchange_ndf");
      this.saveOrUpdate(this.ndfSettled)
        .then((response) => {
          this.$alertSuccess("NDF liquidada");
          this.updateResourceList("ndfs", response);
          this.initialize();
          this.reset();
          this.closeSettled();
        })
        .catch(() => {
          this.$alertError("Houve um problema ao liquidar a NDF");
        });
    },
    manualInputDateSettled(date, prop) {
      this.ndfSettled[prop] = date;
    },
    unsettled() {
      this.ndfSettled.isSettled = false;
      this.ndfSettled.ptax = null;
      this.ndfSettled.settled_date = null;
      this.ndfSettled.settled_fee = null;
      this.ndfSettled.settled_interest = null;
      this.ndfSettled.settled_situation = null;
      this.ndfSettled.profitLoss = null;

      this.setResource("tradeslip_exchange_ndf");
      this.saveOrUpdate(this.ndfSettled)
        .then((response) => {
          this.$alertSuccess("Liquidação desfeita!");
          this.updateResourceList("ndfs", response);
          this.reset();
          this.closeSettled();
        })
        .catch(() => {
          this.$alertError("Houve um problema ao desfazer a liquidação");
        });
    },
    async getPTAXvalue() {
      this.loadingPTAX = true;
      let result = await utilsService.getQuotationsBySecutiry({
        security: "PTAX",
      });
      this.ptax = result.value;
      this.loadingPTAX = false;
    },
  },

  created() {
    this.initialize();
  },

  watch: {
    settledSituation(val) {
      console.log(val);
      if (val.value === "ON_DUE" && !this.ptax) {
        this.getPTAXvalue();
      }
    },
  },
};
</script>

<style>
</style>